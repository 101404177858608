import EstatusSolicitudService from "../services/EstatusSolicitudService";

const state = {};

const estatus_solicitud_service = new EstatusSolicitudService();

const actions = {
  async get_({
    commit
  }) {

    return new Promise((resolve, reject) => {

      estatus_solicitud_service.get()
        .then((result) => {
          resolve(result.data);
        }).catch(error => reject(error));

    });

  }
};

const mutations = {};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};