import SolicitudService from "../services/SolicitudService";

const state = {};

const solicitudes_service = new SolicitudService();

const actions = {
  async get_by_slug({
    commit
  }, slug) {
    return new Promise((resolve, reject) => {

      solicitudes_service
        .get_by_slug(slug)
        .then((result) => {
          resolve(result.data);
        }).catch(error => reject(error));
    });

  },
  async get_by_id_encriptado({
    commit
  }, id) {
    return new Promise((resolve, reject) => {

      solicitudes_service
        .get_by_id_encriptado(id)
        .then((result) => {
          resolve(result.data);
        }).catch(error => reject(error));
    });

  },
  async get_solicitud_pagar({
    commit
  }, id) {
    return new Promise((resolve, reject) => {

      solicitudes_service
        .get_solicitud_pagar(id)
        .then((result) => {
          resolve(result.data);
        }).catch(error => reject(error));
    });

  },
  async save({
    commit
  }, {
    model
  }) {
    return new Promise((resolve, reject) => {

      let service = null;

      if (model.id === 0) {
        service = solicitudes_service.store(
          model
        );
      } else {
        service = solicitudes_service.update(model.id, model);
      }

      service.then((result) => {
        resolve(result);
      }).catch(error => reject(error));

    });
  },
};

const mutations = {};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};