<template>
  <div class="col-12 md:col-12 text-center mb-5" v-if="modelValue.url">
    <Button
      label="Ver archivo"
      class="p-button p-component p-button-primary p-mr-2 p-button-rounded p-md-3"
      @click="fn_abrir_evidencia"
      type="button"
    />
  </div>
  <FileUpload
    name="demo[]"
    :accept="format"
    :showUploadButton="false"
    :showCancelButton="false"
    :maxFileSize="10000000"
    :fileLimit="1"
    invalidFileSizeMessage="El archivo excede el peso máximo (5 MB)."
    invalidFileLimitMessage="Solamente puedes subir un archivo a la vez."
    :customUpload="true"
    :value="modelValue"
    @select="on_select"
    @remove="on_remove"
  />
</template>
<script>
export default {
  name: "upload-component",
  emits: ["update:modelValue"],
  props: {
    modelValue: null,
    format: {
      type: String,
      default: "image/jpeg, image/png",
    },
  },
  data() {
    return {
      is_preview: false,
      file: null,
    };
  },
  computed: {},
  methods: {
    /**
     * Función para actualizar el modelo del FileUpload.
     *
     * @function
     * @param {event} event - El evento del FileUpload.
     */
    on_select(event) {
      this.file = event.files[0];

      this.$emit("update:modelValue", {
        url: this.modelValue.url
          ? this.modelValue.url
          : event.files[0].objectURL,
        file: event.files[0],
      });
    },
    /**
     * Función para eliminar el modelo del FileUpload.
     *
     * @function
     */
    on_remove() {
      this.$emit("update:modelValue", {
        url: "",
        file: null,
      });
    },
    /**
     * Función para abrir en una nueva ventana la evidencia.
     *
     * @function
     */
    fn_abrir_evidencia() {
      window.open(this.modelValue.url, "_blank");
    },
  },
  created() {
    if (this.modelValue.url !== "") this.is_preview = true;
  },
  mounted() {
    this.emitter.on("upload-on-remove-component", () => {
      this.on_remove();
    });
  },
};
</script>

<style></style>
