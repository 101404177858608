<template>
    <main id="main" class="bg-gray-50 dark:bg-gray-900 home_content">
        <router-view></router-view>
    </main>
</template>

<script>
export default {
    name: "main-component",
    data() {
        return {
            profile_photo: "",
        };
    },
    user_service: null,
    upload_service: null,
    computed: {},
    methods: {},
    created() {},
    mounted() {},
};
</script>

<style>
.w50 {
    width: 50% !important;
}

.solicitud-nueva-id {
    height: 33px;
    padding: 4px 0;
    border-radius: 15px;
    font-weight: bold;
    margin: 0 auto;
    background: #f8f9fa;
    width: 100px;
    text-align: center;
}

.home_content {
    height: 100%;
    width: 100%;
    left: 78px;
    transition: all 0.5s ease;
    overflow-y: auto;
}

.home_content::-webkit-scrollbar {
    width: 0.7em;
    display: block;
}

.home_content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.home_content::-webkit-scrollbar-thumb {
    background-color: #e0e0e0;
    outline: 1px solid slategrey;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #689f38 !important;
}

.p-breadcrumb {
    display: inline-block !important;
}

.p-input-icon-left > .p-inputtext {
    padding-left: 2rem !important;
}

.map {
    width: 100%;
    height: 550px;
}

.imagen-listado {
    width: 100px;
    object-fit: cover;
}

button {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.p-inputtext {
    border-radius: 20px !important;
}

button:hover,
button:focus,
button:active {
    -webkit-animation-name: push-on-hover;
    animation-name: push-on-hover;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}

.input-icon {
    position: relative;
}

.limpiar-texto-icono {
    position: absolute;
    top: 19px;
    right: 25px;
    cursor: pointer;
}

.limpiar-texto-icono-form {
    position: absolute;
    top: 10px;
    right: 45px;
    cursor: pointer;
}

.input-help-icon {
    position: absolute;
    top: 17px;
    left: 15px;
    color: #000f9f;
    cursor: pointer;
    font-weight: 900;
    font-size: 21px;
    z-index: 1;
}

.input-help-icon-right {
    color: #000f9f;
    cursor: pointer;
    font-weight: 900;
    font-size: 21px;
    position: absolute;
    right: 0;
    top: 8px;
}

@-webkit-keyframes push-on-hover {
    50% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes push-on-hover {
    50% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@media screen and (max-width: 960px) {
    .p-datatable .p-datatable-tbody > tr > td {
        overflow-wrap: anywhere;
    }
}

@media screen and (max-width: 870px) {
    .p-datepicker-multiple-month .p-datepicker-group-container {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 780px) {
    .ql-container {
        height: 300px !important;
    }
}
</style>
