import UserService from "../services/UserService";

const state = {};

const user_service = new UserService();

const actions = {
    async login({
        commit,
        dispatch
    }, {
        user
    }) {
        return new Promise((resolve, reject) => {
            user_service
                .login({
                    email: user.email,
                    password: user.password
                })
                .then((result) => {
                    resolve(result.data)
                })
                .catch((error) => reject(error));

        })
    },
    async logout({}) {
        return new Promise((resolve, reject) => {
            user_service
                .logout()
                .then((result) => {
                    resolve(result)
                })
                .catch((error) => reject(error));

        })
    },
};

const mutations = {};

const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
