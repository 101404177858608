<template>
  <router-view></router-view>
  <loader-component :is_visible="isLoading"></loader-component>
</template>

<script>
import axios from "./services/AxiosService";

export default {
  name: "App",
  data() {
    return {
      isLoading: false,
      axiosInterceptor: null,
    };
  },
  methods: {
    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use(
        (config) => {
          this.isLoading = true;
          return config;
        },
        (error) => {
          this.isLoading = false;
          return Promise.reject(error);
        }
      );

      axios.interceptors.response.use(
        (response) => {
          this.isLoading = false;
          return response;
        },
        (error) => {
          this.isLoading = false;

          switch (error.response.status) {
            case 422:
              this.fn_display_errors(error.response.data.error);
              break;
            default:
              this.$toast.error(error.response.data.error.custom, {});
          }

          return Promise.reject(error);
        }
      );
    },
    disableInterceptor() {
      axios.interceptors.request.eject(this.axiosInterceptor);
    },
    fn_display_errors(errors) {
      for (const error in errors) {
        for (const item of errors[error]) {
          this.$toast.error(item, {});
        }
      }
    },
  },
  created() {},
  mounted() {
    document.title = process.env.VUE_APP_NAME;
    this.enableInterceptor();
  },
};
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body {
  width: 100% !important;
  height: 100% !important;
  max-width: 1600px;
  margin: 0 auto;
}

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.p-button {
  font-weight: bold !important;
  border-radius: 2rem !important;
  padding: 0.5rem 1rem !important;
}

.p-fieldset {
  min-width: 0 !important;
}

.p-dropdown,
.p-calendar {
  padding: 0 !important;
}

.p-inputtext.p-invalid.p-component {
  border-color: #f44336 !important;
}

.p-dropdown-filter {
  text-transform: uppercase !important;
}

.p-toolbar {
  border: none !important;
  background-color: white !important;
}

.p-dialog-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}

.p-dialog-content::-webkit-scrollbar-thumb {
  background-color: #e0e0e0 !important;
  outline: 1px solid slategrey !important;
}

.busqueda .p-accordion-tab {
  margin-bottom: 20px !important;
}

.busqueda a.p-accordion-header-link {
  background-color: white !important;
  color: black !important;
}

.p-accordion {
  padding: 0 !important;
}

.p-accordion .p-accordion-content {
  padding: 0 1rem !important;
}

.p-inputnumber {
  width: 100% !important;
}

.mandatory::before {
  content: "*";
  color: #ce2323;
}

.help::before {
  font-family: "Font Awesome 5 Free";
  content: "\f059";
  color: #000f9f;
  cursor: pointer;
  font-weight: 900;
}

.enable-status::before {
  font-family: "Font Awesome 5 Free";
  content: "\f058";
  color: #1c9f39;
  font-size: 1.2rem;
  cursor: pointer;
  font-weight: 900;
}

.pending-status::before {
  font-family: "Font Awesome 5 Free";
  content: "\f06a";
  color: #d8ab17;
  font-size: 1.2rem;
  cursor: pointer;
  font-weight: 900;
}

@media only screen and (max-width: 960px) {
  .p-datatable td {
    text-align: right !important;
  }
}
</style>
