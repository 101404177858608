<template>
    <Dialog
        header="Instrucciones"
        :modal="true"
        v-model:visible="abrir_modal"
        :closable="true"
        class="col-12 sm:col-12 md:col-6"
    >
        <div class="solicitud-donde-quiero-llegar">
            <div class="solicitud-donde-quiero-llegar-imagen">
                <img src="/images/car.png" />
            </div>
            <div class="solicitud-donde-quiero-llegar-listado">
                <ul>
                    <li>
                        Para seleccionar el primer destino pon el cursor sobre
                        el destino.
                    </li>
                    <li>
                        Si necesitas seleccionar un destino adicional da clic
                        en:<br />
                        <i class="fas fa-plus icono-mas"></i> <br />y agrega el
                        segundo destino.
                    </li>
                    <li>
                        Para borrar algún destino selecciónalo y haz clic en:<br />
                        <i class="fas fa-minus icono-menos"></i>
                    </li>
                    <li>Al finalizar haz clic en continuar.</li>
                </ul>
            </div>
        </div>
        <template #footer>
            <Button
                label="¡Listo!"
                icon="fas fa-check"
                class="p-button-info p-mr-2 p-button-rounded"
                v-tooltip.bottom="'¡Listo!'"
                @click="abrir_modal = false"
            />
        </template>
    </Dialog>
</template>

<script>
export default {
    name: "solicitud-paso-1-donde-quieres-llegar-component",
    props: {},
    data() {
        return {
            abrir_modal: false,
        };
    },
    methods: {},
    validations() {},
    mounted() {
        this.emitter.on("solicitud-paso-1-donde-quiero-llegar", () => {
            this.abrir_modal = true;
        });
    },
};
</script>

<style>
.solicitud-donde-quiero-llegar {
    padding: 0 15px;
    display: flex;
}
.solicitud-donde-quiero-llegar-listado ul {
    list-style: decimal;
    line-height: 30px;
}

.solicitud-donde-quiero-llegar-imagen {
    width: 25%;
}

.solicitud-donde-quiero-llegar-imagen img {
    padding: 10px;
}

.solicitud-donde-quiero-llegar-listado {
    padding: 0 20px;
    width: 75%;
    font-weight: bold;
    font-size: 20px;
}

.icono-mas {
    font-size: 40px !important;
    color: #689f38;
}

.icono-menos {
    font-size: 40px !important;
    color: #d32f2f;
}

@media screen and (max-width: 520px) {
    .solicitud-donde-quiero-llegar {
        flex-wrap: wrap;
    }
    .solicitud-donde-quiero-llegar-imagen,
    .solicitud-donde-quiero-llegar-listado {
        width: 100%;
    }

    .solicitud-donde-quiero-llegar-imagen img {
        margin: 0 auto;
    }
}
</style>
