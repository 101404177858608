import ClienteService from "../services/ClienteService";

const state = {};

const cliente_service = new ClienteService();

const actions = {
  async get_by_slug({
    commit
  }, slug) {
    return new Promise((resolve, reject) => {

      cliente_service
        .get_by_slug(slug)
        .then((result) => {
          resolve(result.data);
        }).catch(error => reject(error));
    });

  },
  async get_all({
    commit
  }) {
    return new Promise((resolve, reject) => {

      cliente_service
        .get_all()
        .then((result) => {
          resolve(result.data);
        }).catch(error => reject(error));
    });

  }
};

const mutations = {};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};