<template>
    <div
        class="solicitud-pago-pendiente"
        v-if="solicitud && configuracion.logo_url"
    >
        <div class="solicitud-pago-pendiente-logo">
            <img :src="configuracion.logo_url" />
        </div>
        <div class="solicitud-pago-pendiente-contenido">
            <div class="item"><b>Solicitud ID:</b> {{ solicitud.id }}</div>
            <div class="item">
                <b>Servicio:</b> {{ solicitud.servicio.nombre }}
            </div>
        </div>
        <div class="solicitud-pago-pendiente-texto">
            <p>
                <span
                    >IMPORTANTE: estimado conductor, una vez que realices el
                    pago se enviará a tu correo electrónico la autorización de
                    tu solicitud, descárgala y llévala contigo de manera impresa
                    o en formato PDF para comprobar que has realizado el pago
                    correspondiente.</span
                >
            </p>
        </div>
        <div class="solicitud-pago-pendiente-botones">
            <div class="boton">
                <Button
                    label="Nueva solicitud"
                    icon="fas fa-plus"
                    class="p-button-info mr-2 p-button-rounded"
                    v-tooltip.bottom="'Nueva solicitud'"
                    @click="fn_nueva_solicitud"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "solicitud-pago-pendiente-component",
    props: {
        id: String,
    },
    data() {
        return {
            configuracion: {
                logo_url: null,
            },
            solicitud: null,
        };
    },
    computed: {},
    methods: {
        ...mapActions("configuracion", ["get_"]),
        ...mapActions("solicitud", ["get_by_id_encriptado"]),
        fn_descargar_comprobante() {
            window.open(this.solicitud.recibo_pago_pdf_url, "_blank");
        },
        async fn_get_configuracion() {
            this.get_().then((result) => {
                if (result) {
                    this.configuracion.logo_url = result.logo_url;

                    const link =
                        document.querySelector("link[rel*='icon']") ||
                        document.createElement("link");
                    link.type = "image/png";
                    link.rel = "shortcut icon";
                    link.href = this.configuracion.logo_url;
                    document.getElementsByTagName("head")[0].appendChild(link);
                }
            });
        },
        async fn_get_by_id_encriptado() {
            this.get_by_id_encriptado(this.id).then((response) => {
                this.solicitud = response.solicitud;
            });
        },
    },
    validations() {
        return {};
    },
    created() {},
    async mounted() {
        await this.fn_get_by_id_encriptado();
        await this.fn_get_configuracion();
    },
};
</script>

<style>
.solicitud-pago-pendiente {
    padding: 60px;
    width: 50%;
    text-align: center;
    margin: 0 auto;
}

.solicitud-pago-pendiente-contenido .item {
    padding: 5px 0;
}

.solicitud-pago-pendiente-contenido {
    padding: 30px 0 0 0;
}

.solicitud-pago-pendiente-texto p span {
    color: #d32f2f;
    font-weight: bold;
}

.solicitud-pago-pendiente-botones {
    margin-top: 50px;
}

.solicitud-pago-pendiente-texto {
    margin-top: 20px;
}

.solicitud-pago-pendiente-logo img {
    width: 128px;
    height: 128px;
    object-fit: cover;
    margin: 0 auto;
}

@media screen and (max-width: 640px) {
    .solicitud-pago-pendiente {
        width: 100%;
        padding: 30px;
    }
}
</style>
