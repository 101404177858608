import FormaLlegadaService from "../services/FormaLlegadaService";

const state = {};

const forma_llegada_service = new FormaLlegadaService();

const actions = {
  async get_all({
    commit
  }) {

    return new Promise((resolve, reject) => {

      forma_llegada_service.get_all()
        .then((result) => {
          resolve(result.data);
        }).catch(error => reject(error));

    });

  }
};

const mutations = {};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};