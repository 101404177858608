<template>
  <Dialog
    :modal="true"
    v-model:visible="abrir_modal"
    :closable="true"
    class="col-12 sm:col-12 md:col-8"
  >
    <div class="landing-clientes">
      <DataTable
        :rowHover="true"
        :value="clientes"
        v-model:filters="filters"
        responsiveLayout="stack"
        ref="dt"
        dataKey="id"
        :paginator="true"
        :rows="10"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25, 50]"
        currentPageReportTemplate="Mostrando {first} de {last} a {totalRecords} clientes"
        sortMode="multiple"
        :globalFilterFields="['nombre']"
      >
        <template #header>
          <div class="flex align-items-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="Búsqueda"
              />
            </span>
          </div>
        </template>
        <Column
          header="Ciudad - Municipio"
          :sortable="false"
          headerStyle="width: 50%"
        >
          <template #body="slotProps">
            <a target="_blank" :href="slotProps.data.url">{{
              slotProps.data.nombre
            }}</a>
          </template>
        </Column>
        <Column
          field="estado"
          header="Estado"
          :sortable="false"
          headerStyle="width: 50%"
        >
        </Column>
      </DataTable>
    </div>
    <template #footer> </template>
  </Dialog>
</template>

<script>
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { mapActions } from "vuex";
export default {
  name: "landing-clientes-component",
  props: {},
  data() {
    return {
      abrir_modal: false,
      clientes: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
    };
  },
  methods: {
    ...mapActions("cliente", ["get_all"]),
    async fn_get_all() {
      await this.get_all().then((response) => {
        this.clientes = response;
      });
    },
  },
  validations() {},
  async mounted() {
    await this.fn_get_all();

    this.emitter.on("landing-clientes", () => {
      this.abrir_modal = true;
    });
  },
};
</script>

<style>
.landing-clientes a {
  color: #0d6efd !important;
}
</style>
