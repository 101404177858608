<template>
  <div class="solicitud" v-if="solicitud && cliente && configuracion.logo_url">
    <div class="solicitud-show-paso0" v-if="mostrar_paso_0">
      <div class="logo">
        <img :src="configuracion.logo_url" />
      </div>
      <div class="solicitud-show-paso0-texto">
        <p>
          <span class="rojo">
            IMPORTANTE: toda la información presentada a continuación será
            autorizada de manera definitiva cuando realices el pago de esta
            solicitud. </span
          ><br /><br /><br />
          <b>Información pre-aprobada:</b><br /><br />
          Tipo de transporte: {{ solicitud.forma_llegada.nombre }}<br /><br />
          <img :src="solicitud.forma_llegada.url" />
        </p>
        <div class="solicitud-show-paso0-botones">
          <div class="boton">
            <Button
              label="Continuar"
              icon="fas fa-arrow-right"
              class="p-button-info mr-2 p-button-rounded"
              v-tooltip.bottom="'Continuar'"
              @click="
                mostrar_paso_0 = false;
                mostrar_paso_1 = true;
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div class="solicitud-show-paso1" v-show="mostrar_paso_1">
      <div class="logo">
        <img :src="configuracion.logo_url" />
      </div>
      <div class="solicitud-show-paso1-contenido">
        <div class="solicitud-show-paso1-contenido-listado">
          <div class="item">
            <div>
              <b>Estos son los datos que proporcionaste:</b>
            </div>
          </div>
          <div class="item">
            <div>
              <b>Placa:</b>
            </div>
            <div>
              {{ solicitud.placa_vehiculo }}
            </div>
          </div>
          <div class="item">
            <div>
              <b>
                ¿Quién aparece en la tarjeta de circulación como propietario del
                vehículo?</b
              >
            </div>
            <div v-if="solicitud.es_denominacion">
              {{ solicitud.denominacion }}
            </div>
            <div v-else>
              {{ solicitud.nombre_completo }}
            </div>
          </div>
        </div>

        <div class="solicitud-show-paso1-botones">
          <div class="boton">
            <Button
              label="Atrás"
              icon="fas fa-arrow-left"
              class="p-button-info mr-2 p-button-rounded"
              v-tooltip.bottom="'Atrás'"
              @click="
                mostrar_paso_1 = false;
                mostrar_paso_0 = true;
              "
            />
          </div>
          <div class="boton">
            <Button
              label="Continuar"
              icon="fas fa-arrow-right"
              class="p-button-info mr-2 p-button-rounded"
              v-tooltip.bottom="'Continuar'"
              @click="
                mostrar_paso_1 = false;
                mostrar_paso_2 = true;
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div class="solicitud-show-paso2" v-show="mostrar_paso_2">
      <div class="solicitud-show-paso-izquierda">
        <div class="logo">
          <img :src="configuracion.logo_url" />
        </div>
        <div class="solicitud-show-paso-izquierda-contenido">
          <div class="solicitud-show-paso-izquierda-contenido-titulo">
            Esta es la calle por la cual tendrás que ingresar.
          </div>
        </div>
        <div class="solicitud-show-paso3-botones">
          <div class="boton">
            <Button
              label="Atrás"
              icon="fas fa-arrow-left"
              class="p-button-info p-button-rounded boton-continuar"
              v-tooltip.bottom="'Atrás'"
              @click="
                mostrar_paso_2 = false;
                mostrar_paso_1 = true;
              "
            />
          </div>
          <div class="boton">
            <Button
              label="Continuar"
              icon="fas fa-arrow-right"
              class="p-button-info p-button-rounded boton-continuar"
              v-tooltip.bottom="'Continuar'"
              @click="
                mostrar_paso_2 = false;
                mostrar_paso_3 = true;
              "
            />
          </div>
        </div>
      </div>
      <div class="solicitud-show-paso-derecha">
        <div
          class="solicitud-show-paso-derecha-mapa map"
          id="mapa_puntos_acceso"
        ></div>
      </div>
    </div>
    <div class="solicitud-show-paso3" v-show="mostrar_paso_3">
      <div class="solicitud-show-paso-izquierda">
        <div class="logo">
          <img :src="configuracion.logo_url" />
        </div>
        <div class="solicitud-show-paso-izquierda-contenido">
          <div class="solicitud-show-paso-izquierda-contenido-titulo">
            Este es el destino(s) que será(n) autorizado(s).
          </div>
        </div>
        <div class="solicitud-show-paso3-botones">
          <div class="boton">
            <Button
              label="Atrás"
              icon="fas fa-arrow-left"
              class="p-button-info p-button-rounded boton-continuar"
              v-tooltip.bottom="'Atrás'"
              @click="
                mostrar_paso_3 = false;
                mostrar_paso_2 = true;
              "
            />
          </div>
          <div class="boton">
            <Button
              label="Continuar"
              icon="fas fa-arrow-right"
              class="p-button-info p-button-rounded boton-continuar"
              v-tooltip.bottom="'Continuar'"
              @click="
                mostrar_paso_3 = false;
                mostrar_paso_4 = true;
              "
            />
          </div>
        </div>
      </div>
      <div class="solicitud-show-paso-derecha">
        <div
          class="solicitud-show-paso-derecha-mapa map"
          id="mapa_puntos_llegada"
        ></div>
      </div>
    </div>
    <div class="solicitudes-show-paso4" v-if="mostrar_paso_4">
      <div class="logo">
        <img :src="configuracion.logo_url" />
      </div>
      <div class="solicitudes-paso4-texto">
        <div class="solicitudes-paso-titulo">Fecha de llegada autorizada</div>
        <div class="solicitudes-paso4-contenido">
          <p>
            Su llegada será el día
            <span>{{ solicitud.fecha_visita_formatted_humano }}</span
            >.
          </p>
          <div class="solicitudes-paso4-contenido-restricciones">
            <div class="solicitudes-paso4-contenido-restricciones-titulo">
              Restricciones de horario para ese día
            </div>
            <div class="solicitudes-paso4-contenido-restricciones">
              <div class="item" v-if="!solicitud.tiene_restricciones_horario">
                <span class="azul">Ninguna</span><br>
                Esto significa que el visitante puede llegar al destino el día
                {{ solicitud.fecha_visita_formatted_humano }} a cualquier hora
                del día o la noche.
              </div>
              <div class="item" v-else>
                Esto significa que durante este horario no podrá llegar o
                circular.
                <div>
                  De las
                  {{ solicitud.restriccion_1_de_formatted }} a las
                  {{ solicitud.restriccion_1_a_formatted }}.
                </div>
                <div class="item" v-if="solicitud.restriccion2_de">
                  De las
                  {{ solicitud.restriccion_2_de_formatted }} a las
                  {{ solicitud.restriccion_2_a_formatted }}.
                </div>
              </div>
            </div>
            <div class="solicitudes-botones">
              <div class="boton">
                <Button
                  label="Atrás"
                  icon="fas fa-arrow-left"
                  class="p-button-info mr-2 p-button-rounded"
                  @click="
                    mostrar_paso_4 = false;
                    mostrar_paso_3 = true;
                  "
                />
              </div>
              <div class="boton">
                <Button
                  label="Continuar"
                  icon="fas fa-arrow-right"
                  class="p-button-info mr-2 p-button-rounded"
                  v-tooltip.bottom="'Continuar'"
                  @click="
                    mostrar_paso_4 = false;
                    mostrar_paso_5 = true;
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="solicitudes-paso6" v-show="mostrar_paso_5">
      <div class="solicitud-show-paso-izquierda">
        <div class="logo">
          <img :src="configuracion.logo_url" />
        </div>
        <div class="solicitud-show-paso-izquierda-contenido">
          <div class="solicitud-show-paso-izquierda-contenido-titulo">
            Esta es la ruta que deberás seguir cuando llegues para poder
            circular por la ciudad.
          </div>
        </div>
        <div class="solicitud-show-paso3-botones">
          <div class="boton">
            <Button
              label="Atrás"
              icon="fas fa-arrow-left"
              class="p-button-info p-button-rounded boton-continuar"
              v-tooltip.bottom="'Atrás'"
              @click="
                mostrar_paso_5 = false;
                mostrar_paso_4 = true;
              "
            />
          </div>
          <div class="boton">
            <Button
              label="Continuar"
              icon="fas fa-arrow-right"
              class="p-button-info p-button-rounded boton-continuar"
              v-tooltip.bottom="'Continuar'"
              @click="
                mostrar_paso_5 = false;
                mostrar_paso_6 = true;
              "
            />
          </div>
        </div>
      </div>
      <div class="solicitud-show-paso-derecha">
        <div
          class="solicitud-show-paso-derecha-mapa map"
          id="mapa_ruta_llegada"
        ></div>
      </div>
    </div>
    <div class="solicitudes-paso3" v-show="mostrar_paso_6">
      <div class="solicitud-show-paso-izquierda">
        <div class="logo">
          <img :src="configuracion.logo_url" />
        </div>
        <div class="solicitud-show-paso-izquierda-contenido">
          <div class="solicitud-show-paso-izquierda-contenido-titulo">
            Esta es la calle por la cual tendrás que salir de la ciudad
          </div>
        </div>
        <div class="solicitud-show-paso3-botones">
          <div class="boton">
            <Button
              label="Atrás"
              icon="fas fa-arrow-left"
              class="p-button-info p-button-rounded boton-continuar"
              v-tooltip.bottom="'Atrás'"
              @click="
                mostrar_paso_6 = false;
                mostrar_paso_5 = true;
              "
            />
          </div>
          <div class="boton">
            <Button
              label="Continuar"
              icon="fas fa-arrow-right"
              class="p-button-info p-button-rounded boton-continuar"
              v-tooltip.bottom="'Continuar'"
              @click="
                mostrar_paso_6 = false;
                mostrar_paso_7 = true;
              "
            />
          </div>
        </div>
      </div>
      <div class="solicitud-show-paso-derecha">
        <div
          class="solicitud-show-paso-derecha-mapa map"
          id="mapa_puntos_salida"
        ></div>
      </div>
    </div>
    <div class="solicitudes-paso5" v-show="mostrar_paso_7">
      <div class="solicitud-show-paso-izquierda">
        <div class="logo">
          <img :src="configuracion.logo_url" />
        </div>
        <div class="solicitud-show-paso-izquierda-contenido">
          <div class="solicitud-show-paso-izquierda-contenido-titulo">
            Y esta es la ruta que deberás usar para salir de la ciudad.
            <br />
            RECUERDA: tienes hasta el día
            {{ solicitud.fecha_visita_salida_formatted_humano }}
            para salir de la ciudad al amparo de esta solicitud.
          </div>
        </div>
        <div class="solicitud-show-paso3-botones">
          <div class="boton">
            <Button
              label="Atrás"
              icon="fas fa-arrow-left"
              class="p-button-info p-button-rounded boton-continuar"
              v-tooltip.bottom="'Atrás'"
              @click="
                mostrar_paso_7 = false;
                mostrar_paso_6 = true;
              "
            />
          </div>
          <div class="boton">
            <Button
              v-if="solicitud.estatus_solicitud_id < 4"
              label="Continuar"
              icon="fas fa-arrow-right"
              class="p-button-info p-button-rounded boton-continuar"
              v-tooltip.bottom="'Continuar'"
              @click="
                mostrar_paso_7 = false;
                mostrar_paso_8 = true;
              "
            />
          </div>
        </div>
      </div>
      <div class="solicitud-show-paso-derecha">
        <div
          class="solicitud-show-paso-derecha-mapa map"
          id="mapa_ruta_salida"
        ></div>
      </div>
    </div>
    <div
      class="solicitud-show-paso8"
      v-if="mostrar_paso_8 && solicitud.estatus_solicitud_id < 4"
    >
      <div class="logo">
        <img :src="configuracion.logo_url" />
      </div>
      <div class="solicitud-show-paso8-contenido">
        <div class="solicitud-show-paso8-texto">
          Costo total:
          {{ $functions.fn_to_currency(solicitud.monto_pago) }}
        </div>
        <div class="solicitud-show-paso8-botones">
          <div class="boton">
            <Button
              label="Atrás"
              icon="fas fa-arrow-left"
              class="p-button-info p-button-rounded boton-continuar"
              v-tooltip.bottom="'Atrás'"
              @click="
                mostrar_paso_8 = false;
                mostrar_paso_7 = true;
              "
            />
          </div>
          <div class="boton">
            <Button
              label="Realiza tu pago"
              icon="fas fa-arrow-right"
              class="p-button-info mr-2 p-button-rounded"
              v-tooltip.bottom="'Realiza tu pago'"
              @click="fn_realizar_pago"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "solicitud-show-component",
  props: {
    id: String,
  },
  data() {
    return {
      cliente: null,
      cliente_slug: this.slug,
      configuracion: {
        logo_url: "",
      },
      solicitud: null,
      punto_acceso_seleccionado: null,
      mapa_puntos_llegada: null,
      mapa_puntos_acceso: null,
      marcadores_puntos_llegada: [],
      marcadores_puntos_acceso: [],
      mapa_ruta_llegada: null,
      mapa_ruta_salida: null,
      punto_salida_seleccionado: null,
      mapa_puntos_salida: null,
      mostrar_paso_0: true,
      mostrar_paso_1: false,
      mostrar_paso_2: false,
      mostrar_paso_3: false,
      mostrar_paso_4: false,
      mostrar_paso_5: false,
      mostrar_paso_6: false,
      mostrar_paso_7: false,
      mostrar_paso_8: false,
    };
  },
  solicitud_service: null,
  computed: {},
  methods: {
    ...mapActions("cliente", ["get_by_slug"]),
    ...mapActions("solicitud", ["get_by_id_encriptado"]),
    ...mapActions("configuracion", ["get_"]),
    fn_realizar_pago() {
      this.$router.push({
        path: `/solicitudes/pago/${this.id}`,
      });
    },
    async fn_get_configuracion() {
      await this.get_().then((result) => {
        if (result) {
          this.configuracion = result;

          const link =
            document.querySelector("link[rel*='icon']") ||
            document.createElement("link");
          link.type = "image/png";
          link.rel = "shortcut icon";
          link.href = this.configuracion.logo_url;
          document.getElementsByTagName("head")[0].appendChild(link);
        }
      });
    },
    async fn_get_by_id_encriptado() {
      await this.get_by_id_encriptado(this.id).then((response) => {
        this.solicitud = response.solicitud;
        this.cliente_slug = response.slug;
      });
    },
    async fn_get_by_slug() {
      await this.get_by_slug(this.cliente_slug).then((response) => {
        if (!response) {
          this.$router.push({
            name: "page-not-found-component",
            params: { catchAll: "pagina-no-encontrada" },
          });
        } else {
          this.cliente = response;
        }
      });
    },
    fn_initialize_map() {
      this.$functions.fn_load_maps_script();
      window.init_map = this.fn_init_map;
    },
    fn_init_map() {
      try {
        const opciones_mapa = [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
        ];

        /********************************************** Puntos de llegada **********************************************/
        this.mapa_puntos_llegada = new window.google.maps.Map(
          document.getElementById("mapa_puntos_llegada"),
          {
            center: {
              lat: this.cliente.puntos_acceso[0].latitud,
              lng: this.cliente.puntos_acceso[0].longitud,
            },
            zoom: 13,
            mapTypeId: "roadmap",
            zoomControl: true,
            draggable: true,
          }
        );

        if (this.solicitud.puntos_llegada.length > 0) {
          let contador = 1;
          for (const item of this.solicitud.puntos_llegada) {
            new window.google.maps.Marker({
              position: this.fn_get_lat_lng(item.latitud, item.longitud),
              map: this.mapa_puntos_llegada,
              icon: {
                url: `/images/marcadores/${contador}.png`,
                scaledSize: new google.maps.Size(30, 40),
              },
            });

            contador++;
          }
        }

        /********************************************** Puntos de acceso **********************************************/
        this.mapa_puntos_acceso = new window.google.maps.Map(
          document.getElementById("mapa_puntos_acceso"),
          {
            center: {
              lat: this.cliente.puntos_acceso[0].latitud,
              lng: this.cliente.puntos_acceso[0].longitud,
            },
            zoom: 13,
            mapTypeId: "roadmap",
            zoomControl: true,
            draggable: true,
          }
        );

        if (this.cliente.puntos_acceso.length > 0) {
          for (const item of this.cliente.puntos_acceso) {
            if (item.id === this.solicitud.cliente_punto_acceso_id) {
              const marker = new window.google.maps.Marker({
                position: this.fn_get_lat_lng(item.latitud, item.longitud),
                map: this.mapa_puntos_acceso,
                icon: {
                  url: `/images/marcadores/1.png`,
                  scaledSize: new google.maps.Size(30, 40),
                },
              });
              this.punto_acceso_seleccionado = marker;
              break;
            }
          }
        }

        /********************************************** Puntos de salida **********************************************/
        this.mapa_puntos_salida = new window.google.maps.Map(
          document.getElementById("mapa_puntos_salida"),
          {
            center: {
              lat: this.solicitud.puntos_salida[0].latitud,
              lng: this.solicitud.puntos_salida[0].longitud,
            },
            zoom: 13,
            mapTypeId: "roadmap",
            zoomControl: true,
          }
        );

        if (this.solicitud.puntos_salida.length > 0) {
          for (const item of this.solicitud.puntos_salida) {
            if (item.id === this.solicitud.cliente_punto_salida_id) {
              const marker = new window.google.maps.Marker({
                position: this.fn_get_lat_lng(item.latitud, item.longitud),
                map: this.mapa_puntos_salida,
                icon: {
                  url: `/images/marcadores/1.png`,
                  scaledSize: new google.maps.Size(30, 40),
                },
              });

              this.punto_salida_seleccionado = marker;

              break;
            }
          }
        }

        /********************************************** Ruta de llegada **********************************************/
        this.mapa_ruta_llegada = new window.google.maps.Map(
          document.getElementById("mapa_ruta_llegada"),
          {
            center: {
              lat: this.solicitud.latitud_puntos_llegada,
              lng: this.solicitud.longitud_puntos_llegada,
            },
            zoom: 13,
            mapTypeId: "roadmap",
            zoomControl: true,
          }
        );

        this.fn_calcular_ruta_llegada();

        /********************************************** Ruta de salida **********************************************/
        this.mapa_ruta_salida = new window.google.maps.Map(
          document.getElementById("mapa_ruta_salida"),
          {
            center: {
              lat: this.solicitud.latitud_puntos_llegada,
              lng: this.solicitud.longitud_puntos_llegada,
            },
            zoom: 13,
            mapTypeId: "roadmap",
            zoomControl: true,
          }
        );

        this.fn_calcular_ruta_salida();

        this.mapa_puntos_llegada.setOptions({
          styles: opciones_mapa,
        });

        this.mapa_puntos_acceso.setOptions({
          styles: opciones_mapa,
        });

        this.mapa_puntos_salida.setOptions({
          styles: opciones_mapa,
        });
      } catch (error) {
        this.$toast.error(
          "Ha ocurrido un error, consulta al administrador.",
          {}
        );
      }
    },
    fn_calcular_ruta_llegada() {
      const $this = this;
      const directions_service = new google.maps.DirectionsService();
      const directions_renderer_ruta_llegada =
        new google.maps.DirectionsRenderer({
          polylineOptions: {
            strokeColor: "blue",
            strokeWeight: 5,
          },
          suppressMarkers: true,
        });

      directions_renderer_ruta_llegada.setMap(this.mapa_ruta_llegada);

      const way_points = [];

      if (this.solicitud.ruta_llegada.way_points) {
        for (const item of this.solicitud.ruta_llegada.way_points_formatted) {
          way_points.push({
            location: item,
            stopover: true,
          });
        }
      }

      directions_service
        .route({
          origin: this.solicitud.ruta_llegada.ruta_inicio,
          destination: this.solicitud.ruta_llegada.ruta_fin,
          waypoints: way_points,
          optimizeWaypoints: true,
          travelMode: google.maps.TravelMode.DRIVING,
        })
        .then((response) => {
          const abecedario = [
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z",
          ];

          const coordenadas_inicio =
            $this.solicitud.ruta_llegada.ruta_inicio.split(",");
          const coordenadas_fin =
            $this.solicitud.ruta_llegada.ruta_fin.split(",");

          let contador_letra = 0;

          $this.crear_marcador(
            new window.google.maps.LatLng(
              coordenadas_inicio[0],
              coordenadas_inicio[1]
            ),
            $this.mapa_ruta_llegada,
            abecedario[contador_letra]
          );

          const ignorar = !$this.solicitud.ruta_llegada.ignorar
            ? null
            : $this.solicitud.ruta_llegada.ignorar.split("|");

          if (way_points.length > 0) {
            for (let index = 0; index < way_points.length; index++) {
              const contador = index + 1;

              if (!ignorar || !ignorar.includes(contador.toString())) {
                const coordenadas = way_points[index].location.split(",");

                contador_letra++;

                $this.crear_marcador(
                  new window.google.maps.LatLng(coordenadas[0], coordenadas[1]),
                  $this.mapa_ruta_llegada,
                  abecedario[contador_letra]
                );
              }
            }
          }

          $this.crear_marcador(
            new window.google.maps.LatLng(
              coordenadas_fin[0],
              coordenadas_fin[1]
            ),
            $this.mapa_ruta_llegada,
            abecedario[contador_letra + 1]
          );

          directions_renderer_ruta_llegada.setDirections(response);
        })
        .catch(() => {
          this.$toast.error("Ha ocurrido un error al trazar la ruta.", {});
        });
    },
    crear_marcador(lat_lng, mapa, letra) {
      new google.maps.Marker({
        position: lat_lng,
        map: mapa,
        icon:
          "http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=" +
          letra +
          "|FF0000|FFFFFF",
      });
    },
    fn_calcular_ruta_salida() {
      const $this = this;
      const puntos_llegada = this.solicitud.puntos_llegada;
      const directions_service = new google.maps.DirectionsService();
      const directions_renderer_ruta_salida =
        new google.maps.DirectionsRenderer({
          polylineOptions: {
            strokeColor: "blue",
            strokeWeight: 5,
          },
          suppressMarkers: true,
        });

      directions_renderer_ruta_salida.setMap(this.mapa_ruta_salida);

      const ruta_inicio = new google.maps.LatLng(
        puntos_llegada[puntos_llegada.length - 1].latitud,
        puntos_llegada[puntos_llegada.length - 1].longitud
      );

      const ruta_fin = new google.maps.LatLng(
        this.punto_salida_seleccionado.getPosition().lat(),
        this.punto_salida_seleccionado.getPosition().lng()
      );

      const waypoints = [];

      if (this.solicitud.ruta_salida.way_points) {
        for (const item of this.solicitud.ruta_salida.way_points_formatted) {
          waypoints.push({
            location: item,
            stopover: true,
          });
        }
      }

      directions_service
        .route({
          origin: ruta_inicio,
          destination: ruta_fin,
          waypoints,
          travelMode: google.maps.TravelMode.DRIVING,
        })
        .then((response) => {
          $this.crear_marcador(ruta_inicio, $this.mapa_ruta_salida, "A");

          $this.crear_marcador(ruta_fin, $this.mapa_ruta_salida, "B");

          directions_renderer_ruta_salida.setDirections(response);
        })
        .catch(() => {
          this.$toast.error("Ha ocurrido un error al trazar la ruta.", {});
        });
    },
    fn_get_lat_lng(lat, lng) {
      return new window.google.maps.LatLng(lat, lng);
    },
  },
  created() {},
  async mounted() {
    await this.fn_get_by_id_encriptado();
    await this.fn_get_by_slug();
    await this.fn_get_configuracion();
    this.fn_initialize_map();
  },
};
</script>

<style>
span.rojo {
  color: #d32f2f;
  font-weight: bold;
}

span.azul {
  color: #0288d1;
  font-weight: bold;
}

.solicitud {
  width: 100%;
  padding: 30px;
}

.solicitud-show-paso0,
.solicitud-show-paso1 {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  display: flex;
}

.solicitud-show-paso0-texto p {
  padding: 0 20px;
}

.solicitud-show-paso0-texto p img {
  margin: 0 auto;
}

.solicitud .logo {
  width: 100px;
}

.solicitud .logo img {
  width: 100px;
}

.solicitud-show-paso1-contenido,
.solicitudes-paso4-texto,
.solicitud-show-paso8-contenido {
  width: calc(100% - 100px);
}

.solicitud-show-paso8-contenido {
  text-align: center;
}

.solicitud-show-paso8-botones {
  padding: 20px 0;
}

.solicitudes-paso4-texto {
  width: calc(100% - 100px);
  text-align: center;
}

.solicitud-show-paso1-contenido-listado .item {
  padding: 10px 0;
}

.solicitud-show-paso1-contenido-listado .item > div {
  padding: 5px 0;
}

.solicitud-show-paso-izquierda {
  width: 45%;
  padding: 0 10px;
  position: relative;
}

.solicitud-show-paso-derecha {
  width: 55%;
}

.solicitud-show-paso-izquierda-contenido {
  margin-top: 20px;
}

.solicitud-show-paso-izquierda-contenido .formgrid {
  margin-top: 20px;
}

.solicitud-show-paso-izquierda-contenido-titulo,
.solicitud-show-paso2-titulo,
.solicitud-show-paso8-texto {
  font-size: 20px;
  font-weight: bold;
}

.solicitud-show-paso-izquierda-contenido-descripcion {
  padding-top: 10px;
}

.solicitud-show-paso-izquierda-contenido-descripcion span {
  color: #0288d1;
  font-weight: bold;
}

.solicitud-show-paso2-titulo {
  padding: 10px 0;
  text-align: center;
}

.solicitud-show-paso2-contenido {
  text-align: center;
  margin-top: 30px;
}

.solicitud-show-paso5-contenido,
.solicitud-show-paso6-contenido {
  text-align: center;
  margin-top: 30px;
  width: 100%;
}

.solicitud-show-paso1-botones {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.solicitud-show-paso3-botones {
  display: flex;
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.solicitud-show-paso2,
.solicitud-show-paso3,
.solicitudes-show-paso4,
.solicitudes-paso6,
.solicitudes-paso3,
.solicitudes-paso5,
.solicitud-show-paso8 {
  display: flex;
  flex-wrap: wrap;
}

.solicitud-show-paso4-botones {
  width: 45%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
}

.solicitud-show-paso2-botones,
.solicitud-show-paso5-botones,
.solicitud-show-paso6-botones {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}

.solicitud-show-paso1-botones {
  margin-top: 40px;
}

.solicitud-show-paso1-botones .boton,
.solicitud-show-paso5-botones .boton,
.solicitud-show-paso6-botones .boton .solicitud-show-paso2-botones .boton,
.solicitud-show-paso3-botones .boton,
.solicitud-show-paso4-botones .boton,
.solicitud-show-paso8-botones .boton {
  width: 50%;
  text-align: center;
}

.solicitud-show-paso8-botones {
  display: flex;
}

.solicitud-show-paso-derecha-medios-transporte-item-imagen img {
  width: 128px;
  height: 128px;
  object-fit: cover;
}

.solicitud-show-boton-mas {
  border: none !important;
  font-size: 40px !important;
}

.solicitudes-paso-titulo,
.solicitudes-paso4-contenido-restricciones-titulo {
  padding: 18px 0;
  font-weight: bold;
  font-size: 20px;
}

.solicitudes-botones {
  width: 100%;
  display: flex;
  margin-top: 30px;
}

.solicitudes-botones .boton {
  width: 50%;
}

.solicitud-show-boton-menos {
  border: none !important;
  font-size: 40px !important;
}

.solicitud-show-paso-derecha-medios-transporte {
  display: flex;
  flex-wrap: wrap;
}

.solicitud-show-paso-derecha-medios-transporte-item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.solicitud-show-paso-derecha-medios-transporte-item-texto {
  padding: 20px 50px;
}

.solicitud-show-paso-derecha.paso3 {
  padding-top: 100px;
}

.solicitud-show-paso5 .italic,
.solicitud-show-paso6 .italic {
  margin-top: 20px;
}

.solicitud-show-paso5-contenido-input-contenedor,
.solicitud-show-paso6-contenido-input-contenedor {
  display: flex;
  width: 100%;
}

.solicitud-show-paso5-contenido-input,
.solicitud-show-paso6-contenido-input {
  position: relative;
  display: flex;
  align-items: center;
}

.solicitud-show-paso5-contenido-input-contenedor input,
.solicitud-show-paso6-contenido-input-contenedor input {
  width: calc(100% - 30px);
}

.solicitud-show-paso6-contenido-input-radio {
  margin-right: 10px;
}

.solicitud-show-paso6-contenido-or {
  padding: 10px 0;
}

.solicitud-show-paso0-texto {
  padding: 30px 0;
  width: calc(100% - 100px);
}

.solicitud-show-paso0-botones {
  text-align: right;
}

@media screen and (max-width: 1040px) {
  .solicitud-show-paso-izquierda,
  .solicitud-show-paso-derecha,
  .solicitud-show-paso8-contenido {
    width: 100%;
    text-align: center;
  }

  .solicitud-show-paso1-botones,
  .solicitud-show-paso3-botones {
    margin: 10px 0;
    position: relative;
  }

  .solicitud-show-paso-derecha {
    margin-top: 50px;
  }
  .solicitud-show-paso-derecha.paso3 {
    padding-top: 0;
  }
  .solicitud-show-paso-derecha {
    margin-top: 10px;
  }
  .solicitud-show-paso4-botones {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .solicitudes-paso4-texto {
    width: 100%;
    padding: 0 10px;
  }
  .solicitud-show-paso0-botones {
    margin-top: 20px;
  }
  .solicitud-show-paso8-contenido {
    margin-top: 30px;
  }
  .solicitud-show-paso0,
  .solicitud-show-paso1 {
    flex-wrap: wrap;
    width: 100%;
  }
  .solicitud .logo {
    margin: 0 auto;
    width: 100%;
  }
  .solicitud .logo img {
    margin: 0 auto;
  }
  .solicitud-show-paso0-texto,
  .solicitud-show-paso1-contenido {
    width: 100%;
  }
  .solicitud-show-paso0-texto p {
    padding: 0;
  }
  .solicitud-show-paso-derecha {
    margin-top: 0;
    position: initial;
  }
  .solicitud-show-paso1-botones {
  }
  .boton-continuar .p-button .p-button-icon-left {
    margin-right: 0;
  }
  .boton-continuar {
    font-size: 20px;
  }
  .solicitud-show-paso3-botones .boton {
    padding: 0 20px;
  }
}

@media screen and (max-width: 640px) {
  .solicitud-show-paso0-logos-imagen img {
    width: 128px;
  }
  .solicitud-show-paso0,
  .solicitud-show-paso1 {
    padding: 30px 15px;
  }
}

@media screen and (max-width: 480px) {
  .solicitud {
    padding: 20px 0;
  }
  .solicitud-show-paso-izquierda-contenido-titulo,
  .solicitud-show-paso2-titulo {
    font-size: 20px;
    font-weight: bold;
  }
  .solicitud-show-paso8-botones {
    flex-wrap: wrap;
  }
  .solicitud-show-paso8-botones .boton {
    width: 100%;
    margin: 10px 0;
  }
  .solicitud-show-paso1-botones .boton {
    width: 100%;
    padding: 10px 20px;
  }

  .solicitud-show-paso-derecha-medios-transporte-item > div {
    width: 100%;
    text-align: center;
  }

  .solicitud-show-paso-derecha-medios-transporte-item-imagen img {
    margin: 0 auto;
  }
  .solicitud-show-paso-derecha-medios-transporte-item-texto {
    padding: 20px;
  }
}
</style>
