<template>
  <div class="solicitud-pago" v-if="solicitud && configuracion.logo_url">
    <div class="solicitud-pago-logo">
      <img :src="configuracion.logo_url" />
    </div>
    <div class="solicitud-pago-contenido">
      <div class="item"><b>Solicitud ID:</b> {{ solicitud.id }}</div>
      <div class="item"><b>Servicio:</b> {{ solicitud.servicio.nombre }}</div>
      <div class="item">
        <b>Costo:</b>
        {{ $functions.fn_to_currency(solicitud.monto_pago) }}
      </div>
    </div>
  </div>
  <div class="solicitud-pago-botones">
    <div id="wallet_container"></div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "solicitud-pago-component",
  props: {
    id: String,
  },
  data() {
    return {
      configuracion: {
        logo_url: "",
      },
      solicitud: null,
      preference_id: null,
    };
  },
  computed: {},
  methods: {
    ...mapActions("cliente", ["get_by_slug"]),
    ...mapActions("forma_llegada", ["get_all"]),
    ...mapActions("solicitud", ["store", "get_solicitud_pagar"]),
    ...mapActions("configuracion", ["get_"]),
    async fn_get_configuracion() {
      this.get_().then((result) => {
        if (result) {
          this.configuracion.logo_url = result.logo_url;

          const link =
            document.querySelector("link[rel*='icon']") ||
            document.createElement("link");
          link.type = "image/png";
          link.rel = "shortcut icon";
          link.href = this.configuracion.logo_url;
          document.getElementsByTagName("head")[0].appendChild(link);
        }
      });
    },
    async fn_get_solicitud_pagar() {
      await this.get_solicitud_pagar(this.id).then((response) => {
        if (!response) {
          this.$router.push({
            name: "page-not-found-component",
            params: { catchAll: "pagina-no-encontrada" },
          });
        } else {
          this.solicitud = response.solicitud;
          this.preference_id = response.preference_id;

          this.fn_cargar_mercado_pago();
        }
      });
    },
    fn_cargar_mercado_pago() {
      const mp = new MercadoPago(process.env.VUE_APP_MP_PUBLIC_KEY);
      mp.bricks().create("wallet", "wallet_container", {
        initialization: {
          preferenceId: this.preference_id,
        },
      });
    },
  },
  validations() {
    return {};
  },
  created() {},
  async mounted() {
    await this.fn_get_solicitud_pagar();
    await this.fn_get_configuracion();
  },
};
</script>

<style>
.solicitud-pago {
  text-align: center;
  padding: 50px 0 10px 0;
}

.solicitud-pago-contenido .item {
  padding: 5px 0;
}

.solicitud-pago-contenido {
  padding: 30px 0 0 0;
}

.solicitud-pago-botones {
  display: flex;
  justify-content: center;
}

.solicitud-pago-logo img {
  width: 128px;
  height: 128px;
  object-fit: cover;
  margin: 0 auto;
}

div#wallet_container {
  width: 500px;
  text-align: center;
}
</style>
