<template>
  <!--====== HEADER PART START ======-->
  <header class="header-area">
    <div class="navbar-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <nav class="navbar navbar-expand-lg">
              <a class="navbar-brand" href="/">
                <img src="assets/images/logo.png" alt="Logo" />
              </a>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="toggler-icon"> </span>
                <span class="toggler-icon"> </span>
                <span class="toggler-icon"> </span>
              </button>

              <div
                class="collapse navbar-collapse sub-menu-bar"
                id="navbarSupportedContent"
              >
                <ul id="nav" class="navbar-nav ms-auto">
                  <li class="nav-item">
                    <a class="page-scroll active" href="#home">Inicio</a>
                  </li>
                  <li class="nav-item">
                    <a class="page-scroll" href="#features">Características</a>
                  </li>
                  <li class="nav-item">
                    <a class="page-scroll" href="#about">Acerca de</a>
                  </li>
                  <div class="navbar-btn" v-if="login_url">
                    <a class="main-btn" :href="login_url" target="_blank">
                      Iniciar sesión
                    </a>
                  </div>
                </ul>
              </div>
            </nav>
            <!-- navbar -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>
    <!-- navbar area -->
    <div
      id="home"
      class="header-hero bg_cover"
      style="background-image: url(assets/images/header/banner-bg.svg)"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="header-hero-content text-center">
              <h3
                class="header-sub-title wow fadeInUp"
                data-wow-duration="1.3s"
                data-wow-delay="0.2s"
              >
                TRÁMITE DIGITAL
              </h3>
              <h2
                class="header-title wow fadeInUp"
                data-wow-duration="1.3s"
                data-wow-delay="0.5s"
              >
                Transformando trámites en experiencias positivas
              </h2>
              <p
                class="text wow fadeInUp"
                data-wow-duration="1.3s"
                data-wow-delay="0.8s"
              >
                Comienza tu solicitud de permiso para carga y descarga.
              </p>
              <a
                href="javascript:void(0)"
                class="main-btn wow fadeInUp"
                data-wow-duration="1.3s"
                data-wow-delay="1.1s"
                @click="emitter.emit('landing-clientes')"
              >
                Inicia tu trámite
              </a>
            </div>
            <!-- header hero content -->
          </div>
        </div>
      </div>
      <!-- container -->
      <div id="particles-1" class="particles"></div>
    </div>
    <!-- header hero -->
  </header>
  <!--====== HEADER PART ENDS ======-->

  <!--====== SERVICES PART START ======-->
  <section id="features" class="services-area pt-120">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="section-title text-center pb-40">
            <div class="line m-auto"></div>
            <h3 class="title">
              Fácil de usar,
              <span> ¡encuentra aquí todo lo que necesitas para comenzar!</span>
            </h3>
          </div>
          <!-- section title -->
        </div>
      </div>
      <!-- row -->
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-7 col-sm-8">
          <div
            class="single-services text-center mt-30 wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <div class="services-icon">
              <img
                class="shape"
                src="assets/images/services/services-shape.svg"
                alt="shape"
              />
              <img
                class="shape-1"
                src="assets/images/services/services-shape-1.svg"
                alt="shape"
              />
              <i class="lni lni-baloon"> </i>
            </div>
            <div class="services-content mt-30">
              <h4 class="services-title">
                <a href="javascript:void(0)">Limpio</a>
              </h4>
              <p class="text">Diseño limpio de uso fácil.</p>
            </div>
          </div>
          <!-- single services -->
        </div>
        <div class="col-lg-4 col-md-7 col-sm-8">
          <div
            class="single-services text-center mt-30 wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          >
            <div class="services-icon">
              <img
                class="shape"
                src="assets/images/services/services-shape.svg"
                alt="shape"
              />
              <img
                class="shape-1"
                src="assets/images/services/services-shape-2.svg"
                alt="shape"
              />
              <i class="lni lni-cog"> </i>
            </div>
            <div class="services-content mt-30">
              <h4 class="services-title">
                <a href="javascript:void(0)">Robusto</a>
              </h4>
              <p class="text">
                Diseñado con los mejores estándares de programación.
              </p>
            </div>
          </div>
          <!-- single services -->
        </div>
        <div class="col-lg-4 col-md-7 col-sm-8">
          <div
            class="single-services text-center mt-30 wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.8s"
          >
            <div class="services-icon">
              <img
                class="shape"
                src="assets/images/services/services-shape.svg"
                alt="shape"
              />
              <img
                class="shape-1"
                src="assets/images/services/services-shape-3.svg"
                alt="shape"
              />
              <i class="lni lni-bolt-alt"> </i>
            </div>
            <div class="services-content mt-30">
              <h4 class="services-title">
                <a href="javascript:void(0)">Poderoso</a>
              </h4>
              <p class="text">Seguro, privado y estable en todo momento.</p>
            </div>
          </div>
          <!-- single services -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </section>
  <!--====== SERVICES PART ENDS ======-->

  <section id="about">
    <!--====== ABOUT PART START ======-->
    <div class="about-area pt-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div
              class="about-content mt-50 wow fadeInLeftBig"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              <div class="section-title">
                <div class="line"></div>
                <h3 class="title">
                  Aplicación de uso intuitivo,
                  <span>comprueba lo fácil que es usarlo.</span>
                </h3>
              </div>
              <!-- section title -->
              <p class="text">
                Descubre la simplicidad en cada paso con Trámite Digital.
                Nuestra plataforma intuitiva hace que los permisos de carga y
                descarga con el gobierno sean tan fáciles como un clic. Olvídate
                de complicaciones y sumérgete en una experiencia amigable,
                diseñada para que cualquier ciudadano pueda navegar sin
                esfuerzo. Con Trámite Digital, la complejidad se convierte en
                simplicidad, brindándote un camino suave hacia la gestión
                eficiente de tus trámites para circular con carga.
              </p>
              <a
                href="javascript:void(0)"
                class="main-btn"
                @click="emitter.emit('landing-clientes')"
                >Inicia tu trámite</a
              >
            </div>
            <!-- about content -->
          </div>
          <div class="col-lg-6">
            <div
              class="about-image text-center mt-50 wow fadeInRightBig"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              <img src="assets/images/about/about1.svg" alt="about" />
            </div>
            <!-- about image -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
      <div class="about-shape-1">
        <img src="assets/images/about/about-shape-1.svg" alt="shape" />
      </div>
    </div>
    <!--====== ABOUT PART ENDS ======-->

    <!--====== ABOUT PART START ======-->
    <div class="about-area pt-70">
      <div class="about-shape-2">
        <img src="assets/images/about/about-shape-2.svg" alt="shape" />
      </div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 order-lg-last">
            <div
              class="about-content ms-lg-auto mt-50 wow fadeInLeftBig"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              <div class="section-title">
                <div class="line"></div>
                <h3 class="title">
                  Diseño moderno <span> con características únicas</span>
                </h3>
              </div>
              <!-- section title -->
              <p class="text">
                Nuestra plataforma fusiona un estilo contemporáneo con
                características únicas para proporcionarte una experiencia
                amigable. Cada detalle ha sido cuidadosamente pensado para
                ofrecer funcionalidades esenciales que faciliten la generación
                de tus permisos para circular con carga. Con un enfoque en la
                estética y la practicidad, Trámite Digital redefine la
                experiencia del usuario, ofreciendo un equilibrio perfecto entre
                forma y función.
              </p>
              <a
                href="javascript:void(0)"
                class="main-btn"
                @click="emitter.emit('landing-clientes')"
                >Inicia tu trámite</a
              >
            </div>
            <!-- about content -->
          </div>
          <div class="col-lg-6 order-lg-first">
            <div
              class="about-image text-center mt-50 wow fadeInRightBig"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              <img src="assets/images/about/about2.svg" alt="about" />
            </div>
            <!-- about image -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>
    <!--====== ABOUT PART ENDS ======-->

    <!--====== ABOUT PART START ======-->
    <div class="about-area pt-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div
              class="about-content mt-50 wow fadeInLeftBig"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              <div class="section-title">
                <div class="line"></div>
                <h3 class="title">
                  Eficiencia gubernamental:
                  <span> transforma tu municipio con Trámite Digital.</span>
                </h3>
              </div>
              <!-- section title -->
              <p class="text">
                Da un paso hacia la modernización de la gestión municipal con
                TRÁMITE DIGITAL. Más allá de simplificar la vida de los
                ciudadanos, nuestra plataforma líder promueve el cumplimiento
                normativo, actuando como un escudo contra actos de corrupción.
                <strong
                  >TRÁMITE DIGITAL ofrece una solución integral que potencia la
                  eficiencia operativa en el trámite de permisos para circular
                  con carga dentro del municipio,</strong
                >
                mientras fortalece la conexión entre la administración y la
                comunidad. Descubre cómo podemos llevar a tu municipio hacia una
                era de servicio público más ágil y transparente, donde la
                integridad y la eficacia se fusionan para construir un gobierno
                que inspire confianza en cada trámite
              </p>
            </div>
            <!-- about content -->
          </div>
          <div class="col-lg-6">
            <div
              class="about-image text-center mt-50 wow fadeInRightBig"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              <img src="assets/images/about/about3.svg" alt="about" />
            </div>
            <!-- about image -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
      <div class="about-shape-1">
        <img src="assets/images/about/about-shape-1.svg" alt="shape" />
      </div>
    </div>
    <!--====== ABOUT PART ENDS ======-->
  </section>

  <!--====== FOOTER PART START ======-->
  <footer id="footer" class="footer-area pt-120">
    <div class="container">
      <div
        class="subscribe-area wow fadeIn"
        data-wow-duration="1s"
        data-wow-delay="0.5s"
      >
        <div class="row">
          <div class="col-lg-6">
            <div class="subscribe-content mt-45">
              <h2 class="subscribe-title">
                ¿Necesitas mas información? <span>Contáctanos</span>
              </h2>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="subscribe-form mt-50">
              <form action="#">
                <div class="formgrid grid p-3">
                  <div class="field col-12 sm:col-12 md:col-12">
                    <InputText
                      class="col-12 shadow appearance-none border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      placeholder="Nombre"
                      :class="{
                        'p-invalid':
                          v$.formulario.nombre.$invalid &&
                          v$.formulario.nombre.$dirty,
                      }"
                      v-model.trim="formulario.nombre"
                      @blur="v$.formulario.nombre.$touch"
                    />
                    <p
                      v-for="error of v$.formulario.nombre.$errors"
                      :key="error.$uid"
                    >
                      <small class="p-error">{{ error.$message }}</small>
                    </p>
                  </div>
                  <div class="field col-12 sm:col-12 md:col-12">
                    <InputText
                      class="col-12 shadow appearance-none border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      placeholder="Correo electrónico"
                      :class="{
                        'p-invalid':
                          v$.formulario.correo_electronico.$invalid &&
                          v$.formulario.correo_electronico.$dirty,
                      }"
                      v-model.trim="formulario.correo_electronico"
                      @blur="v$.formulario.correo_electronico.$touch"
                    />
                    <p
                      v-for="error of v$.formulario.correo_electronico.$errors"
                      :key="error.$uid"
                    >
                      <small class="p-error">{{ error.$message }}</small>
                    </p>
                  </div>
                  <div class="field col-12 sm:col-12 md:col-12">
                    <InputText
                      class="col-12 shadow appearance-none border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      placeholder="Teléfono"
                      :class="{
                        'p-invalid':
                          v$.formulario.telefono.$invalid &&
                          v$.formulario.telefono.$dirty,
                      }"
                      v-model.trim="formulario.telefono"
                      @blur="v$.formulario.telefono.$touch"
                    />
                    <p
                      v-for="error of v$.formulario.telefono.$errors"
                      :key="error.$uid"
                    >
                      <small class="p-error">{{ error.$message }}</small>
                    </p>
                  </div>
                  <div class="field col-12 sm:col-12 md:col-12">
                    <Textarea
                      class="col-12 shadow appearance-none border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      autoResize
                      rows="5"
                      cols="30"
                      placeholder="Mensaje"
                      :class="{
                        'p-invalid':
                          v$.formulario.mensaje.$invalid &&
                          v$.formulario.mensaje.$dirty,
                      }"
                      v-model.trim="formulario.mensaje"
                      @blur="v$.formulario.mensaje.$touch"
                    />
                    <p
                      v-for="error of v$.formulario.mensaje.$errors"
                      :key="error.$uid"
                    >
                      <small class="p-error">{{ error.$message }}</small>
                    </p>
                  </div>
                  <div class="field col-12 sm:col-12 md:col-12 text-right">
                    <Button
                      type="button"
                      label="Enviar"
                      class="p-button-info p-button-rounded"
                      v-tooltip.bottom="'Enviar'"
                      @click="fn_send_correo"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- subscribe area -->
      <div class="footer-widget pb-100" v-if="configuracion">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-8">
            <div
              class="footer-about mt-50 wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <a class="logo" href="javascript:void(0)">
                <img src="assets/images/logo.png" alt="logo" />
              </a>
              <p class="text">
                Lorem ipsum dolor sit amet consetetur sadipscing elitr, sederfs
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam.
              </p>
              <ul class="social">
                <li>
                  <a href="javascript:void(0)">
                    <i class="lni lni-facebook-filled"> </i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i class="lni lni-twitter-filled"> </i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i class="lni lni-instagram-filled"> </i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i class="lni lni-linkedin-original"> </i>
                  </a>
                </li>
              </ul>
            </div>
            <!-- footer about -->
          </div>
          <div class="col-lg-5 col-md-7 col-sm-12">
            <div class="footer-link d-flex mt-50 justify-content-sm-between">
              <div
                class="link-wrapper wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.4s"
              >
                <div class="footer-title">
                  <h4 class="title">Enlace rápido</h4>
                </div>
                <ul class="link">
                  <li>
                    <a :href="formulario.aviso_privacidad_url" target="_blank"
                      >Política de privacidad</a
                    >
                  </li>
                  <li>
                    <a
                      :href="formulario.terminos_y_condiciones_url"
                      target="_blank"
                      >Términos del servicio</a
                    >
                  </li>
                </ul>
              </div>
              <!-- footer wrapper -->
              <div
                class="link-wrapper wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.6s"
                style="display: none"
              >
                <div class="footer-title">
                  <h4 class="title">Contáctenos</h4>
                </div>
                <ul class="link"></ul>
              </div>
              <!-- footer wrapper -->
            </div>
            <!-- footer link -->
          </div>
          <div class="col-lg-3 col-md-5 col-sm-12">
            <div
              class="footer-contact mt-50 wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.8s"
            >
              <div class="footer-title">
                <h4 class="title">Contáctenos</h4>
              </div>
              <ul class="contact">
                <li><a href="tel:+524425995910">442 599 5910</a></li>
                <li>
                  <a href="mailto:contacto@tramitedigital.com.mx"
                    >contacto@tramitedigital.com.mx</a
                  >
                </li>
              </ul>
            </div>
            <!-- footer contact -->
          </div>
        </div>
        <!-- row -->
      </div>
    </div>
  </footer>
  <!--====== FOOTER PART ENDS ======-->

  <!--====== BACK TOP TOP PART START ======-->
  <a href="#" class="back-to-top"> <i class="lni lni-chevron-up"> </i> </a>
  <!--====== BACK TOP TOP PART ENDS ======-->

  <!-- Modales -->
  <landing-clientes-component></landing-clientes-component>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { mapActions } from "vuex";
import { email, helpers, required } from "@vuelidate/validators";
export default {
  name: "landing-component",
  data() {
    return {
      configuracion: null,
      v$: useVuelidate(),
      formulario: {
        nombre: "",
        correo_electronico: "",
        telefono: "",
        mensaje: "",
      },
      login_url: null,
    };
  },
  methods: {
    ...mapActions("landing_page", ["send_correo"]),
    ...mapActions("configuracion", ["get_"]),
    on_load() {
      // ======= Sticky
      window.onscroll = function () {
        // show or hide the back-top-top button
        const backToTop = document.querySelector(".back-to-top");
        if (
          document.body.scrollTop > 50 ||
          document.documentElement.scrollTop > 50
        ) {
          backToTop.style.display = "flex";
        } else {
          backToTop.style.display = "none";
        }
      };

      // ==== for menu scroll
      const pageLink = document.querySelectorAll(".page-scroll");

      pageLink.forEach((elem) => {
        elem.addEventListener("click", (e) => {
          e.preventDefault();
          document.querySelector(elem.getAttribute("href")).scrollIntoView({
            behavior: "smooth",
            offsetTop: 1 - 60,
          });
        });
      });

      // section menu active
      function onScroll(event) {
        const sections = document.querySelectorAll(".page-scroll");
        const scrollPos =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;

        for (let i = 0; i < sections.length; i++) {
          const currLink = sections[i];
          const val = currLink.getAttribute("href");
          const refElement = document.querySelector(val);
          const scrollTopMinus = scrollPos + 73;
          if (
            refElement.offsetTop <= scrollTopMinus &&
            refElement.offsetTop + refElement.offsetHeight > scrollTopMinus
          ) {
            document.querySelector(".page-scroll").classList.remove("active");
            currLink.classList.add("active");
          } else {
            currLink.classList.remove("active");
          }
        }
      }

      window.document.addEventListener("scroll", onScroll);

      //===== close navbar-collapse when a  clicked
      let navbarToggler = document.querySelector(".navbar-toggler");
      const navbarCollapse = document.querySelector(".navbar-collapse");

      document.querySelectorAll(".page-scroll").forEach((e) =>
        e.addEventListener("click", () => {
          navbarToggler.classList.remove("active");
          navbarCollapse.classList.remove("show");
        })
      );
      navbarToggler.addEventListener("click", function () {
        navbarToggler.classList.toggle("active");
      });

      // ========= glightbox
      const myGallery = GLightbox({
        href: "https://www.youtube.com/watch?v=r44RKWyfcFw",
        type: "video",
        source: "youtube", //vimeo, youtube or local
        width: 900,
        autoplayVideos: true,
      });

      //====== counter up
      const cu = new counterUp({
        start: 0,
        duration: 2000,
        intvalues: true,
        interval: 100,
        append: "k",
      });
      cu.start();

      //=====  WOW active
      new WOW().init();

      //=====  particles
      if (document.getElementById("particles-1"))
        particlesJS("particles-1", {
          particles: {
            number: {
              value: 40,
              density: {
                enable: !0,
                value_area: 4000,
              },
            },
            color: {
              value: ["#FFFFFF", "#FFFFFF", "#FFFFFF"],
            },
            shape: {
              type: "circle",
              stroke: {
                width: 0,
                color: "#fff",
              },
              polygon: {
                nb_sides: 5,
              },
              image: {
                src: "img/github.svg",
                width: 33,
                height: 33,
              },
            },
            opacity: {
              value: 0.15,
              random: !0,
              anim: {
                enable: !0,
                speed: 0.2,
                opacity_min: 0.15,
                sync: !1,
              },
            },
            size: {
              value: 50,
              random: !0,
              anim: {
                enable: !0,
                speed: 2,
                size_min: 5,
                sync: !1,
              },
            },
            line_linked: {
              enable: !1,
              distance: 150,
              color: "#ffffff",
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: !0,
              speed: 1,
              direction: "top",
              random: !0,
              straight: !1,
              out_mode: "out",
              bounce: !1,
              attract: {
                enable: !1,
                rotateX: 600,
                rotateY: 600,
              },
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: !1,
                mode: "bubble",
              },
              onclick: {
                enable: !1,
                mode: "repulse",
              },
              resize: !0,
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 1,
                },
              },
              bubble: {
                distance: 250,
                size: 0,
                duration: 2,
                opacity: 0,
                speed: 3,
              },
              repulse: {
                distance: 400,
                duration: 0.4,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: !0,
        });

      if (document.getElementById("particles-2"))
        particlesJS("particles-2", {
          particles: {
            number: {
              value: 40,
              density: {
                enable: !0,
                value_area: 4000,
              },
            },
            color: {
              value: ["#FFFFFF", "#FFFFFF", "#FFFFFF"],
            },
            shape: {
              type: "circle",
              stroke: {
                width: 0,
                color: "#fff",
              },
              polygon: {
                nb_sides: 5,
              },
              image: {
                src: "img/github.svg",
                width: 33,
                height: 33,
              },
            },
            opacity: {
              value: 0.15,
              random: !0,
              anim: {
                enable: !0,
                speed: 0.2,
                opacity_min: 0.15,
                sync: !1,
              },
            },
            size: {
              value: 50,
              random: !0,
              anim: {
                enable: !0,
                speed: 2,
                size_min: 5,
                sync: !1,
              },
            },
            line_linked: {
              enable: !1,
              distance: 150,
              color: "#ffffff",
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: !0,
              speed: 1,
              direction: "top",
              random: !0,
              straight: !1,
              out_mode: "out",
              bounce: !1,
              attract: {
                enable: !1,
                rotateX: 600,
                rotateY: 600,
              },
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: !1,
                mode: "bubble",
              },
              onclick: {
                enable: !1,
                mode: "repulse",
              },
              resize: !0,
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 1,
                },
              },
              bubble: {
                distance: 250,
                size: 0,
                duration: 2,
                opacity: 0,
                speed: 3,
              },
              repulse: {
                distance: 400,
                duration: 0.4,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: !0,
        });
    },
    async fn_get_configuracion() {
      await this.get_().then((result) => {
        if (result) {
          this.configuracion = result;

          if (this.configuracion) {
            const link =
              document.querySelector("link[rel*='icon']") ||
              document.createElement("link");
            link.type = "image/png";
            link.rel = "shortcut icon";
            link.href = this.formulario.logo_url;
            document.getElementsByTagName("head")[0].appendChild(link);
          }
        }
      });
    },
    fn_send_correo() {
      try {
        this.v$.formulario.$touch();

        if (this.v$.formulario.$invalid) {
          return;
        }

        this.send_correo({ formulario: this.formulario }).then(() => {
          this.v$.formulario.$reset();
          this.$toast.success(
            "El correo fue enviado, pronto nos comunicaremos contigo.",
            {}
          );

          this.formulario.nombre = "";
          this.formulario.correo_electronico = "";
          this.formulario.telefono = "";
          this.formulario.mensaje = "";
        });
      } catch (error) {
        this.$toast.error(
          "Ha ocurrido un error al guardar, consulta al administrador.",
          {}
        );
      }
    },
  },
  validations() {
    return {
      formulario: {
        nombre: {
          required: helpers.withMessage("El nombre es requerido.", required),
        },
        correo_electronico: {
          required: helpers.withMessage(
            "El correo electrónico es requerido.",
            required
          ),
          email: helpers.withMessage(
            "El correo electrónico no tiene el formato correcto.",
            email
          ),
        },
        telefono: {
          required: helpers.withMessage("El teléfono requerido.", required),
        },
        mensaje: {
          required: helpers.withMessage("El mensaje requerido.", required),
        },
      },
    };
  },
  async mounted() {
    this.on_load();
    await this.fn_get_configuracion();

    this.login_url = `${process.env.VUE_APP_URL_ADMIN}/login`;
  },
};
</script>

<style>
.unauthorized {
  position: relative;
  top: 20%;
}
</style>
