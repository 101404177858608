<template>
  <Dialog
    :modal="true"
    v-model:visible="abrir_modal"
    :closable="false"
    class="col-12 sm:col-12 md:col-6"
  >
    <div class="solicitud-enviada">
      <div class="solicitud-enviada-imagen">
        <img src="/images/car.png" />
      </div>
      <div class="solicitud-enviada-texto">
        Tu solicitud se ha enviado con éxito para ser analizada dentro del
        horario de atención.<br /><br />
        Después de ser revisada, recibirás un correo electrónico con el estatus
        de tu solicitud y los datos para realizar el pago correspondiente, o
        bien; se te informará si hizo falta algún dato.<br /><br />
        <span class="rojo">IMPORTANTE:</span> revisa también tu bandeja de spam.
      </div>
    </div>
    <template #footer>
      <Button
        label="Nueva solicitud"
        icon="fas fa-plus"
        class="p-button-info p-mr-2 p-button-rounded"
        v-tooltip.bottom="'Nueva solicitud'"
        @click="fn_nueva_solicitud"
      />
    </template>
  </Dialog>
</template>

<script>
export default {
  name: "solicitud-enviada-component",
  props: {},
  data() {
    return {
      abrir_modal: false,
      url_nueva_solicitud: null,
    };
  },
  methods: {
    fn_nueva_solicitud() {
      const route = this.$router.resolve({
        path: this.url_nueva_solicitud,
      });
      location.href = route.href;
    },
  },
  validations() {},
  mounted() {
    this.emitter.on("solicitud-enviada", (slug) => {
      this.url_nueva_solicitud = `/${slug}`;
      this.abrir_modal = true;
    });
  },
};
</script>

<style>
.solicitud-enviada {
  padding: 0 15px;
  display: flex;
}

.solicitud-enviada-imagen {
  width: 25%;
}

.solicitud-enviada-imagen img {
  padding: 10px;
}

.solicitud-enviada-texto {
  padding: 0 20px;
  width: 75%;
  font-weight: bold;
  font-size: 20px;
}

.icono-mas {
  font-size: 40px !important;
  color: #689f38;
}

.icono-menos {
  font-size: 40px !important;
  color: #d32f2f;
}

@media screen and (max-width: 520px) {
  .solicitud-enviada {
    flex-wrap: wrap;
  }
  .solicitud-enviada-imagen,
  .solicitud-enviada-texto {
    width: 100%;
  }

  .solicitud-enviada-imagen img {
    margin: 0 auto;
  }
}
</style>
