import {
  createApp
} from 'vue';

import App from './App.vue'

import moment from 'moment-timezone';

// Funciones
import {
  functions
} from './functions';

// Toast
import Toaster from "@meforma/vue-toaster";

// Router
import router from './router';

// Store
import store from './store';

import {
  v4 as uuidv4
} from 'uuid';

// Vue ClickAway
import VueClickAway from "vue3-click-away";

import LandingClientesComponent from './components/Modal/LandingClientesComponent';

// MasterPage
import MainComponent from './components/MasterPage/MainComponent';

// Layouts
import ErrorPageComponent from './components/Layouts/ErrorPageComponent';
import PageNotFoundComponent from './components/Layouts/PageNotFoundComponent';

// Loader
import LoaderComponent from './components/Loader/LoaderComponent';

// Solicitud
import SolicitudPaso1DondeQuieresLlegarComponent from './components/Solicitudes/Modal/Paso1DondeQuieresLlegarComponent';
import SolicitudPaso3LugarAccesoComponent from './components/Solicitudes/Modal/Paso3LugarAccesoComponent';
import SolicitudPaso3InstruccionesComponent from './components/Solicitudes/Modal/Paso3InstruccionesComponent';
import SolicitudPaso4LugarAccesoComponent from './components/Solicitudes/Modal/Paso4LugarAccesoComponent';
import SolicitudPaso4InstruccionesComponent from './components/Solicitudes/Modal/Paso4InstruccionesComponent';
import SolicitudPaso5AyudaComponent from './components/Solicitudes/Modal/Paso5AyudaComponent';
import SolicitudPaso5InstruccionesComponent from './components/Solicitudes/Modal/Paso5InstruccionesComponent';
import SolicitudPaso5LugarSalidaComponent from './components/Solicitudes/Modal/Paso5LugarSalidaComponent';
import SolicitudPaso6PlacaComponent from './components/Solicitudes/Modal/Paso6PlacaComponent';
import SolicitudPaso6RazonSocialComponent from './components/Solicitudes/Modal/Paso6RazonSocialComponent';
import SolicitudConfirmarComponent from './components/Solicitudes/Modal/ConfirmarSolicitudComponent';
import SolicitudEnviadaComponent from './components/Solicitudes/Modal/EnviadaComponent';
import SolicitudContactanosComponent from './components/Solicitudes/Modal/ContactanosComponent';

// PrimeVue
import PrimeVue from 'primevue/config';
import Sidebar from 'primevue/sidebar';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Tooltip from 'primevue/tooltip';
import PanelMenu from 'primevue/panelmenu';
import Card from 'primevue/card';
import Breadcrumb from 'primevue/breadcrumb';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Toolbar from 'primevue/toolbar';
import InputNumber from 'primevue/inputnumber';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import RadioButton from 'primevue/radiobutton';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import UploadComponent from './components/Layouts/UploadComponent';
import Message from 'primevue/message';
import Password from 'primevue/password';
import ProgressBar from 'primevue/progressbar';
import Toast from 'primevue/toast';
import Calendar from 'primevue/calendar';
import Editor from 'primevue/editor';
import Checkbox from 'primevue/checkbox';
import InputSwitch from 'primevue/inputswitch';
import InputMask from 'primevue/inputmask';
import 'primeflex/primeflex.css';
import 'primevue/resources/themes/saga-blue/theme.css' //theme
import 'primevue/resources/primevue.min.css' //core css
import 'primeicons/primeicons.css' //icons

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import mitt from 'mitt';

import {
  QuillEditor
} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {
  settings
} from './settings';

const emitter = mitt();

const app = createApp(App);

app.use(store);
app.use(router);
app.use(VueClickAway);
app.use(Toaster, {
  position: "top-right"
});
app.use(PrimeVue, {
  locale: {
    startsWith: 'Inicia con',
    contains: 'Contiene',
    notContains: 'No contiene',
    endsWith: 'Termina con',
    equals: 'Igual',
    notEquals: 'Diferente',
    noFilter: 'Sin filtro',
    lt: 'Menos que',
    lte: 'Menos que o igual a',
    gt: 'Más grande que',
    gte: 'Más grande que o igual a',
    dateIs: 'La fecha es',
    dateIsNot: 'La fecha no es',
    dateBefore: 'La fecha es antes',
    dateAfter: 'La fecha es después',
    clear: 'Limpiar',
    apply: 'Aplicar',
    matchAll: 'Seleccionar todo',
    matchAny: 'Selecionar cualquiera',
    addRule: 'Agregar regla',
    removeRule: 'Eliminar regla',
    accept: 'Sí',
    reject: 'No',
    choose: 'Elegir',
    upload: 'Subir',
    cancel: 'Cancelar',
    dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
    dayNamesShort: ["dom.", "lun.", "mar.", "miérc.", "juev.", "vier.", "sáb."],
    dayNamesMin: ["DO", "LU", "MA", "MI", "JU", "VI", "SA"],
    monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    monthNamesShort: ["en.", "feb.", "mar.", "abr.", "may.", "jun.", "jul.", "ag.", "sept.", "oct.", "nov.", "dic."],
    today: 'Hoy',
    weekHeader: 'Sem',
    firstDayOfWeek: 0,
    dateFormat: 'dd/mm/yy',
    weak: 'Débil',
    medium: 'Medio',
    strong: 'Fuerte',
    passwordPrompt: 'Escribe una contraseña',
    emptyFilterMessage: 'No hay información',
    emptyMessage: 'No hay información'
  }
});

// Components
app.component('QuillEditor', QuillEditor)
app.component('Sidebar', Sidebar);
app.component('Button', Button);
app.component('Menubar', Menubar);
app.component('PanelMenu', PanelMenu);
app.component('Card', Card);
app.component('Breadcrumb', Breadcrumb);
app.component('InputText', InputText);
app.component('Textarea', Textarea);
app.component('Dropdown', Dropdown);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('ColumnGroup', ColumnGroup);
app.component('Toolbar', Toolbar);
app.component('Password', Password);
app.component('InputNumber', InputNumber);
app.component('Dialog', Dialog);
app.component('RadioButton', RadioButton);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
app.component('upload-component', UploadComponent);
app.component('Message', Message);
app.component('Divider', Divider);
app.component('ProgressBar', ProgressBar);
app.component('Menu', Menu);
app.component('Editor', Editor);
app.component('Checkbox', Checkbox);
app.component('InputSwitch', InputSwitch);
app.component('InputMask', InputMask);
app.component('Toast', Toast);
app.component('Calendar', Calendar);
app.component('VueDatePicker', VueDatePicker);

app.component('MainComponent', MainComponent);
app.component('LandingClientesComponent', LandingClientesComponent);
app.component('ErrorPageComponent', ErrorPageComponent);
app.component('PageNotFoundComponent', PageNotFoundComponent);

app.component('LoaderComponent', LoaderComponent);
app.component('SolicitudPaso1DondeQuieresLlegarComponent', SolicitudPaso1DondeQuieresLlegarComponent);
app.component('SolicitudPaso3LugarAccesoComponent', SolicitudPaso3LugarAccesoComponent);
app.component('SolicitudPaso3InstruccionesComponent', SolicitudPaso3InstruccionesComponent);
app.component('SolicitudPaso4LugarAccesoComponent', SolicitudPaso4LugarAccesoComponent);
app.component('SolicitudPaso4InstruccionesComponent', SolicitudPaso4InstruccionesComponent);
app.component('SolicitudPaso5AyudaComponent', SolicitudPaso5AyudaComponent);
app.component('SolicitudPaso6PlacaComponent', SolicitudPaso6PlacaComponent);
app.component('SolicitudPaso6RazonSocialComponent', SolicitudPaso6RazonSocialComponent);
app.component('SolicitudConfirmarComponent', SolicitudConfirmarComponent);
app.component('SolicitudEnviadaComponent', SolicitudEnviadaComponent);
app.component('SolicitudContactanosComponent', SolicitudContactanosComponent);
app.component('SolicitudPaso5LugarSalidaComponent', SolicitudPaso5LugarSalidaComponent);
app.component('SolicitudPaso5InstruccionesComponent', SolicitudPaso5InstruccionesComponent);


app.directive('tooltip', Tooltip);

app.config.globalProperties.$functions = functions;
app.config.globalProperties.$settings = settings;
app.config.globalProperties.moment = moment;
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.uuidv4 = uuidv4;

app.mount('#app');