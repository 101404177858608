<template>
    <div class="text-center unauthorized">
        <div class="text-2xl mb-10">Ha ocurrido el siguiente error:</div>
        <div class="font-bold text-3xl mb-5">
            {{ error }}
        </div>
        <Button
            label="Regresar"
            class="p-button-raised p-button-secondary"
            icon="fas fa-undo"
            @click="this.$router.go(-2)"
        />
    </div>
</template>

<script>
export default {
    name: "error-page-component",
    props: {
        error: String,
    },
};
</script>

<style>
.unauthorized {
    position: relative;
    top: 20%;
}
</style>
