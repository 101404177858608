<template>
    <div class="text-center align-page-not-found">
        <div class="font-bold text-3xl mb-5">
            No existe la página que quieres acceder.
        </div>
    </div>
</template>

<script>
export default {
    name: "page-not-found-component",
};
</script>

<style>
.align-page-not-found {
    position: relative;
    top: 20%;
}
</style>
