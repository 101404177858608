import axios from './AxiosService';

export default class SolicitudService {

  async get_by_slug(slug) {
    return await axios.get(`/cliente/slug/${slug}`).then(response => response.data);
  }

  async store(params) {
    return await axios.post('/solicitudes/store', params).then(response => response.data);
  }

  async update(id, params) {
    return await axios.put(`/solicitudes/update/${id}`, params).then(response => response.data);
  }

  async get_by_id_encriptado(id) {
    return await axios.get(`/solicitudes/${id}`).then(response => response.data);
  }

  async get_solicitud_pagar(id) {
    return await axios.get(`/solicitudes/pago/${id}`).then(response => response.data);
  }

}