import axios from './AxiosService';

export default class UserService {

  async login(obj) {
    return await axios.post('/login', obj).then(result => result.data);
  }

  async logout(obj) {
    return await axios.post('/logout', obj).then(result => result.data);
  }
};