<template>
  <div class="solicitud" v-if="solicitud.cliente && formas_llegada">
    <div class="solicitud-paso0" v-show="mostrar_paso_0">
      <div class="solicitud-paso0-logos">
        <div class="solicitud-paso0-logos-imagen">
          <img :src="solicitud.cliente.configuracion.logo_institucional_url" />
        </div>
        <div class="solicitud-paso0-logos-imagen">
          <img :src="configuracion.logo_url" />
        </div>
      </div>
      <div class="solicitud-paso0-texto">
        <p>
          Estás por iniciar una solicitud en línea de:<br /><br />
          <strong>{{ solicitud.cliente.servicios[0].servicio.nombre }}</strong>
          para llegar al municipio de
          <strong>{{ solicitud.cliente.nombre }}</strong> del estado de
          <strong>{{ solicitud.cliente.estado }}</strong
          >.<br />
          El sistema te guiará durante todo el proceso, es muy sencillo; solo
          sigue los pasos que se irán indicando.<br /><br />
          <template v-if="solicitud.cliente.horario_24_7">
            <span class="rojo">IMPORTANTE:</span> puedes presentar una solicitud
            las 24 horas los 7 días de la semana, el
            <span class="azul">tiempo de respuesta</span> es de aproximadamente
            <span class="azul">1 hora hábil.</span>
          </template>
          <template v-else>
            <span class="rojo">IMPORTANTE:</span> puedes presentar una solicitud
            las 24 horas los 7 días de la semana, pero considera que:<br />
            El horario laboral de nuestro personal para la atención de las
            solicitudes es: {{ horarios_formatted }}.
            <br />
            El <span class="azul">tiempo de respuesta</span> es de
            aproximadamente <span class="azul">1 hora hábil.</span>
          </template>
        </p>
      </div>
      <div class="solicitud-paso0-botones">
        <div class="boton">
          <Button
            label="Continuar"
            icon="fas fa-arrow-right"
            class="p-button-info mr-2 p-button-rounded"
            v-tooltip.bottom="'Continuar'"
            @click="
              mostrar_paso_0 = false;
              mostrar_paso_1 = true;
            "
          />
        </div>
      </div>
      <div class="solicitud-paso0-links">
        <a :href="configuracion.terminos_y_condiciones_url" target="_blank"
          >Términos y condiciones</a
        >
        /
        <a :href="configuracion.aviso_privacidad_url" target="_blank"
          >Aviso de privacidad</a
        >
        /
        <a
          href="javascript:;"
          @click="
            emitter.emit(
              'solicitud-contactanos',
              this.configuracion.correo_electronico
            )
          "
          >Contáctanos</a
        >
      </div>
    </div>
    <div class="solicitud-paso1" v-show="mostrar_paso_1">
      <div class="solicitud-paso-izquierda">
        <div class="logo">
          <img :src="configuracion.logo_url" />
        </div>
        <div class="solicitud-paso-izquierda-contenido">
          <div class="solicitud-paso-izquierda-contenido-titulo">
            ¿A dónde quieres llegar?
          </div>
          <div class="formgrid">
            <div class="field col-12 sm:col-12 md:col-12 input-icon">
              <i
                class="fas fa-question-circle input-help-icon"
                v-tooltip.bottom="'Ayuda'"
                @click="emitter.emit('solicitud-paso-1-donde-quiero-llegar')"
              ></i>
              <InputText
                id="txt_donde_quieres_llegar"
                v-model.trim="solicitud.donde_quieres_llegar"
                class="col-12 shadow appearance-none border py-2 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="¿Por qué requerimos tu lugar de destino?"
              />
              <i
                v-if="solicitud.donde_quieres_llegar.length > 0"
                class="fas fa-times limpiar-texto-icono"
                v-tooltip.bottom="'Limpiar'"
                @click="solicitud.donde_quieres_llegar = ''"
              ></i>
            </div>
          </div>
        </div>
        <div class="solicitud-paso1-botones">
          <div class="boton">
            <Button
              icon="fas fa-plus"
              class="p-button-success mr-2 p-button-rounded p-button-outlined solicitud-boton-mas"
              v-tooltip.bottom="'Agregar destino'"
              @click="fn_agregar_destino"
            />
          </div>
          <div class="boton">
            <Button
              v-if="marcador_actual"
              icon="fas fa-minus"
              class="p-button-danger mr-2 p-button-rounded p-button-outlined solicitud-boton-menos"
              v-tooltip.bottom="'Eliminar destino'"
              @click="fn_eliminar_destino"
            />
          </div>
          <div class="boton">
            <Button
              label="Atrás"
              icon="fas fa-arrow-left"
              class="p-button-info mr-2 p-button-rounded"
              v-tooltip.bottom="'Atrás'"
              @click="
                mostrar_paso_1 = false;
                mostrar_paso_0 = true;
              "
            />
          </div>
          <div class="boton">
            <Button
              label="Continuar"
              icon="fas fa-arrow-right"
              class="p-button-info p-button-rounded boton-continuar"
              v-tooltip.bottom="'Continuar'"
              @click="fn_ir_paso_2"
            />
          </div>
        </div>
      </div>
      <div class="solicitud-paso-derecha">
        <div
          class="solicitud-paso-derecha-mapa map"
          id="mapa_puntos_llegada"
        ></div>
      </div>
    </div>
    <div class="solicitud-paso2" v-if="mostrar_paso_2">
      <div class="logo">
        <img :src="configuracion.logo_url" />
      </div>
      <div class="solicitud-paso2-titulo">¿Cuándo quieres llegar?</div>
      <div class="italic text-center">
        <b>Nota:</b> solo puedes seleccionar una fecha dentro de 14 días
        naturales a partir del día de hoy.
      </div>
      <div class="solicitud-paso2-contenido">
        <Calendar
          v-model="solicitud.fecha_visita"
          inline
          showButtonBar
          :numberOfMonths="2"
          :manualInput="false"
          :minDate="fecha_visita_minima"
          :maxDate="fecha_visita_maxima"
        />
      </div>
      <div class="solicitud-paso2-botones">
        <div class="boton">
          <Button
            label="Atrás"
            icon="fas fa-arrow-left"
            class="p-button-info mr-2 p-button-rounded"
            v-tooltip.bottom="'Atrás'"
            @click="fn_regresar_a_paso_1"
          />
        </div>
        <div class="boton">
          <Button
            label="Continuar"
            icon="fas fa-arrow-right"
            class="p-button-info mr-2 p-button-rounded"
            v-tooltip.bottom="'Continuar'"
            @click="fn_ir_paso_3"
          />
        </div>
      </div>
    </div>
    <div class="solicitud-paso3" v-show="mostrar_paso_3">
      <div class="solicitud-paso-izquierda">
        <div class="logo">
          <img :src="configuracion.logo_url" />
        </div>
        <div class="solicitud-paso-izquierda-contenido">
          <div class="solicitud-paso-izquierda-contenido-titulo">
            Selecciona la calle por la cual quieres llegar a la ciudad
          </div>
          <div class="formgrid">
            <div class="field col-12 sm:col-12 md:col-12 input-icon">
              <i
                class="fas fa-question-circle input-help-icon"
                v-tooltip.bottom="'Ayuda'"
                @click="emitter.emit('solicitud-paso-3-lugar-acceso')"
              ></i>
              <InputText
                disabled
                class="col-12 shadow appearance-none border py-2 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="¿Por qué requerimos tu lugar de destino?"
              />
            </div>
            <div class="field col-12 sm:col-12 md:col-12 input-icon">
              <i
                class="fas fa-question-circle input-help-icon"
                v-tooltip.bottom="'Ayuda'"
                @click="emitter.emit('solicitud-paso-3-instrucciones')"
              ></i>
              <InputText
                disabled
                class="col-12 shadow appearance-none border py-2 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="Instrucciones"
              />
            </div>
          </div>
        </div>
        <div class="solicitud-paso3-botones">
          <div class="boton">
            <Button
              label="Atrás"
              icon="fas fa-arrow-left"
              class="p-button-info p-button-rounded boton-continuar"
              v-tooltip.bottom="'Atrás'"
              @click="fn_regresar_a_paso_2"
            />
          </div>
          <div class="boton">
            <Button
              label="Continuar"
              icon="fas fa-arrow-right"
              class="p-button-info p-button-rounded boton-continuar"
              v-tooltip.bottom="'Continuar'"
              @click="fn_ir_paso_4"
            />
          </div>
        </div>
      </div>
      <div class="solicitud-paso-derecha">
        <div
          class="solicitud-paso-derecha-mapa map"
          id="mapa_puntos_acceso"
        ></div>
      </div>
    </div>
    <div class="solicitud-paso4" v-if="mostrar_paso_4">
      <div class="solicitud-paso-izquierda">
        <div class="logo">
          <img :src="configuracion.logo_url" />
        </div>
        <div class="solicitud-paso-izquierda-contenido">
          <div class="solicitud-paso-izquierda-contenido-titulo">
            ¿Cuál es el medio de transporte que usarás para llegar?
          </div>
          <div class="solicitud-paso-izquierda-contenido-descripcion">
            <p>
              <b
                >NOTA: las imágenes son solo una guía ilustrativa, pueden no
                coincidir plenamente con tu equipo de transporte,
                <span>elige el más parecido a tu medio de transporte.</span></b
              >
            </p>
          </div>
          <div class="formgrid">
            <div class="field col-12 sm:col-12 md:col-12 input-icon">
              <i
                class="fas fa-question-circle input-help-icon"
                v-tooltip.bottom="'Ayuda'"
                @click="emitter.emit('solicitud-paso-4-lugar-acceso')"
              ></i>
              <InputText
                disabled
                class="col-12 shadow appearance-none border py-2 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="¿Por qué requerimos tu medio de transporte?"
              />
            </div>
            <div class="field col-12 sm:col-12 md:col-12 input-icon">
              <i
                class="fas fa-question-circle input-help-icon"
                v-tooltip.bottom="'Ayuda'"
                @click="emitter.emit('solicitud-paso-4-instrucciones')"
              ></i>
              <InputText
                disabled
                class="col-12 shadow appearance-none border py-2 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="Instrucciones"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="solicitud-paso-derecha paso3">
        <div
          class="solicitud-paso-derecha-medios-transporte-item"
          v-for="item of formas_llegada"
          :key="item"
        >
          <div class="solicitud-paso-derecha-medios-transporte-item-radio">
            <RadioButton
              name="rd_formas_llegada"
              :value="item.id"
              v-model="solicitud.forma_llegada"
            />
          </div>
          <div class="solicitud-paso-derecha-medios-transporte-item-texto">
            {{ item.nombre }}
          </div>
          <div class="solicitud-paso-derecha-medios-transporte-item-imagen">
            <img :src="item.url" />
          </div>
        </div>
      </div>
    </div>
    <div class="solicitud-paso4-botones" v-if="mostrar_paso_4">
      <div class="boton">
        <Button
          label="Atrás"
          icon="fas fa-arrow-left"
          class="p-button-info p-button-rounded boton-continuar"
          v-tooltip.bottom="'Atrás'"
          @click="fn_regresar_a_paso_3"
        />
      </div>
      <div class="boton">
        <Button
          label="Continuar"
          icon="fas fa-arrow-right"
          class="p-button-info p-button-rounded boton-continuar"
          v-tooltip.bottom="'Continuar'"
          @click="fn_ir_paso_5"
        />
      </div>
    </div>
    <div class="solicitud-paso3" v-show="mostrar_paso_5">
      <div class="solicitud-paso-izquierda">
        <div class="logo">
          <img :src="configuracion.logo_url" />
        </div>
        <div class="solicitud-paso-izquierda-contenido">
          <div class="solicitud-paso-izquierda-contenido-titulo">
            Selecciona la calle por la cual quieres salir de la ciudad
          </div>
          <div class="formgrid">
            <div class="field col-12 sm:col-12 md:col-12 input-icon">
              <i
                class="fas fa-question-circle input-help-icon"
                v-tooltip.bottom="'Ayuda'"
                @click="emitter.emit('solicitud-paso-5-lugar-salida')"
              ></i>
              <InputText
                disabled
                class="col-12 shadow appearance-none border py-2 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="¿Por qué requerimos tu calle preferida para salir?"
              />
            </div>
            <div class="field col-12 sm:col-12 md:col-12 input-icon">
              <i
                class="fas fa-question-circle input-help-icon"
                v-tooltip.bottom="'Ayuda'"
                @click="emitter.emit('solicitud-paso-5-instrucciones')"
              ></i>
              <InputText
                disabled
                class="col-12 shadow appearance-none border py-2 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="Instrucciones"
              />
            </div>
          </div>
        </div>
        <div class="solicitud-paso3-botones">
          <div class="boton">
            <Button
              label="Atrás"
              icon="fas fa-arrow-left"
              class="p-button-info p-button-rounded boton-continuar"
              v-tooltip.bottom="'Atrás'"
              @click="fn_regresar_a_paso_4"
            />
          </div>
          <div class="boton">
            <Button
              label="Continuar"
              icon="fas fa-arrow-right"
              class="p-button-info p-button-rounded boton-continuar"
              v-tooltip.bottom="'Continuar'"
              @click="fn_ir_paso_6"
            />
          </div>
        </div>
      </div>
      <div class="solicitud-paso-derecha">
        <div
          class="solicitud-paso-derecha-mapa map"
          id="mapa_puntos_salida"
        ></div>
      </div>
    </div>
    <div class="solicitud-paso5" v-if="mostrar_paso_6">
      <div class="logo">
        <img :src="configuracion.logo_url" />
      </div>
      <div class="italic text-center">
        <b>Nota:</b> los campos marcados con asterisco (<span
          class="mandatory"
        ></span
        >) son obligatorios.
      </div>
      <div class="solicitud-paso5-contenido">
        <div class="formgrid">
          <div class="field col-12 sm:col-12 md:col-12 input-icon">
            <label
              ><span class="mandatory"></span>
              <b> Captura tu número telefónico (celular):</b></label
            >
            <div class="solicitud-paso5-contenido-input">
              <div class="solicitud-paso5-contenido-input-contenedor">
                <InputMask
                  :class="{
                    'p-invalid':
                      v$.solicitud.paso_5.numero_telefonico.$invalid &&
                      v$.solicitud.paso_5.numero_telefonico.$dirty,
                  }"
                  class="shadow appearance-none border py-2 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  v-model.trim="solicitud.paso_5.numero_telefonico"
                  @blur="v$.solicitud.paso_5.numero_telefonico.$touch;"
                  mask="999-999-99-99"
                  placeholder="xxx-xxx-xx-xx"
                />
                <i
                  v-if="solicitud.paso_5.numero_telefonico.length > 0"
                  class="fas fa-times limpiar-texto-icono-form"
                  v-tooltip.bottom="'Limpiar'"
                  @click="solicitud.paso_5.numero_telefonico = ''"
                ></i>
              </div>
              <i
                class="fas fa-question-circle input-help-icon-right"
                v-tooltip.bottom="'Ayuda'"
                @click="emitter.emit('solicitud-paso-5-ayuda')"
              ></i>
            </div>
            <p
              v-for="error of v$.solicitud.paso_5.numero_telefonico.$errors"
              :key="error.$uid"
            >
              <small class="p-error">{{ error.$message }}</small>
            </p>
          </div>
          <div class="field col-12 sm:col-12 md:col-12 input-icon">
            <label
              ><span class="mandatory"></span>
              <b> ¿Cuál es tu correo electrónico?:</b></label
            >
            <div class="solicitud-paso5-contenido-input">
              <div class="solicitud-paso5-contenido-input-contenedor">
                <InputText
                  :class="{
                    'p-invalid':
                      v$.solicitud.paso_5.correo_electronico.$invalid &&
                      v$.solicitud.paso_5.correo_electronico.$dirty,
                  }"
                  class="shadow appearance-none border py-2 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  v-model.trim="solicitud.paso_5.correo_electronico"
                  @blur="v$.solicitud.paso_5.correo_electronico.$touch;"
                />
                <i
                  v-if="solicitud.paso_5.correo_electronico.length > 0"
                  class="fas fa-times limpiar-texto-icono-form"
                  v-tooltip.bottom="'Limpiar'"
                  @click="solicitud.paso_5.correo_electronico = ''"
                ></i>
              </div>
              <i
                class="fas fa-question-circle input-help-icon-right"
                v-tooltip.bottom="'Ayuda'"
                @click="emitter.emit('solicitud-paso-5-ayuda')"
              ></i>
            </div>
            <p
              v-for="error of v$.solicitud.paso_5.correo_electronico.$errors"
              :key="error.$uid"
            >
              <small class="p-error">{{ error.$message }}</small>
            </p>
          </div>
        </div>
      </div>
      <div class="solicitud-paso5-botones">
        <div class="boton">
          <Button
            label="Atrás"
            icon="fas fa-arrow-left"
            class="p-button-info mr-2 p-button-rounded"
            v-tooltip.bottom="'Atrás'"
            @click="fn_regresar_a_paso_5"
          />
        </div>
        <div class="boton">
          <Button
            label="Continuar"
            icon="fas fa-arrow-right"
            class="p-button-info mr-2 p-button-rounded"
            v-tooltip.bottom="'Continuar'"
            @click="fn_ir_paso_7"
          />
        </div>
      </div>
    </div>
    <div class="solicitud-paso6" v-if="mostrar_paso_7">
      <div class="logo">
        <img :src="configuracion.logo_url" />
      </div>
      <div class="italic text-center">
        <b>Nota:</b> los campos marcados con asterisco (<span
          class="mandatory"
        ></span
        >) son obligatorios.
      </div>
      <div class="solicitud-paso6-contenido">
        <div class="formgrid">
          <div class="field col-12 sm:col-12 md:col-12 input-icon">
            <label
              ><span class="mandatory"></span>
              <b> ¿Cuál es la placa del vehículo?</b></label
            >
            <div class="solicitud-paso6-contenido-input">
              <div class="solicitud-paso6-contenido-input-contenedor">
                <InputText
                  :class="{
                    'p-invalid':
                      v$.solicitud.paso_6.placa_vehiculo.$invalid &&
                      v$.solicitud.paso_6.placa_vehiculo.$dirty,
                  }"
                  class="shadow uppercase appearance-none border py-2 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  v-model.trim="solicitud.paso_6.placa_vehiculo"
                  @blur="v$.solicitud.paso_6.placa_vehiculo.$touch;"
                />
                <i
                  v-if="solicitud.paso_6.placa_vehiculo.length > 0"
                  class="fas fa-times limpiar-texto-icono-form"
                  v-tooltip.bottom="'Limpiar'"
                  @click="solicitud.paso_6.placa_vehiculo = ''"
                ></i>
              </div>
              <i
                class="fas fa-question-circle input-help-icon-right"
                v-tooltip.bottom="'Ayuda'"
                @click="emitter.emit('solicitud-paso-6-placa')"
              ></i>
            </div>
            <p
              v-for="error of v$.solicitud.paso_6.placa_vehiculo.$errors"
              :key="error.$uid"
            >
              <small class="p-error">{{ error.$message }}</small>
            </p>
          </div>
          <div class="field col-12 sm:col-12 md:col-12 input-icon">
            <label
              ><span class="mandatory"></span>
              <b>
                ¿Quién aparece en la tarjeta de circulación como propietario del
                vehículo?</b
              ></label
            >
            <div class="solicitud-paso6-contenido-input">
              <div class="solicitud-paso6-contenido-input-radio">
                <RadioButton
                  name="rd_propietario"
                  v-model="solicitud.paso_6.es_denominacion"
                  :value="true"
                  @change="on_change_propietario"
                />
              </div>
              <div class="solicitud-paso6-contenido-input-contenedor">
                <InputText
                  :disabled="!solicitud.paso_6.es_denominacion"
                  :class="{
                    'p-invalid':
                      v$.solicitud.paso_6.denominacion.$invalid &&
                      v$.solicitud.paso_6.denominacion.$dirty,
                  }"
                  class="shadow uppercase appearance-none border py-2 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  v-model.trim="solicitud.paso_6.denominacion"
                  @blur="v$.solicitud.paso_6.denominacion.$touch"
                  placeholder="DENOMINACIÓN O RAZÓN SOCIAL"
                />
                <i
                  v-if="solicitud.paso_6.denominacion.length > 0"
                  class="fas fa-times limpiar-texto-icono-form"
                  v-tooltip.bottom="'Limpiar'"
                  @click="solicitud.paso_6.denominacion = ''"
                ></i>
              </div>
              <i
                class="fas fa-question-circle input-help-icon-right"
                v-tooltip.bottom="'Ayuda'"
                @click="emitter.emit('solicitud-paso-6-razon-social')"
              ></i>
            </div>
            <p
              v-for="error of v$.solicitud.paso_6.denominacion.$errors"
              :key="error.$uid"
            >
              <small class="p-error">{{ error.$message }}</small>
            </p>
            <div class="solicitud-paso6-contenido-or">o</div>
            <div class="solicitud-paso6-contenido-input">
              <div class="solicitud-paso6-contenido-input-radio">
                <RadioButton
                  name="rd_propietario"
                  v-model="solicitud.paso_6.es_denominacion"
                  :value="false"
                  @change="on_change_propietario"
                />
              </div>
              <div class="solicitud-paso6-contenido-input-contenedor">
                <InputText
                  :disabled="solicitud.paso_6.es_denominacion"
                  :class="{
                    'p-invalid':
                      v$.solicitud.paso_6.nombre.$invalid &&
                      v$.solicitud.paso_6.nombre.$dirty,
                  }"
                  class="shadow uppercase appearance-none border py-2 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  @blur="v$.solicitud.paso_6.nombre.$touch"
                  v-model.trim="solicitud.paso_6.nombre"
                  placeholder="NOMBRE COMPLETO"
                />
                <i
                  v-if="solicitud.paso_6.nombre.length > 0"
                  class="fas fa-times limpiar-texto-icono-form"
                  v-tooltip.bottom="'Limpiar'"
                  @click="solicitud.paso_6.nombre = ''"
                ></i>
              </div>
              <i
                class="fas fa-question-circle input-help-icon-right"
                v-tooltip.bottom="'Ayuda'"
                @click="emitter.emit('solicitud-paso-6-razon-social')"
              ></i>
            </div>
            <p
              v-for="error of v$.solicitud.paso_6.nombre.$errors"
              :key="error.$uid"
            >
              <small class="p-error">{{ error.$message }}</small>
            </p>
          </div>
        </div>
      </div>
      <div class="solicitud-paso6-botones">
        <div class="boton">
          <Button
            label="Atrás"
            icon="fas fa-arrow-left"
            class="p-button-info mr-2 p-button-rounded"
            v-tooltip.bottom="'Atrás'"
            @click="fn_regresar_a_paso_6"
          />
        </div>
        <div class="boton">
          <Button
            label="Confirmar solicitud"
            icon="fas fa-arrow-right"
            class="p-button-info mr-2 p-button-rounded"
            v-tooltip.bottom="'Confirmar solicitud'"
            @click="fn_confirmar_solicitud"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- Modales -->
  <solicitud-paso-1-donde-quieres-llegar-component></solicitud-paso-1-donde-quieres-llegar-component>
  <solicitud-paso-3-lugar-acceso-component></solicitud-paso-3-lugar-acceso-component>
  <solicitud-paso-3-instrucciones-component></solicitud-paso-3-instrucciones-component>
  <solicitud-paso-4-lugar-acceso-component></solicitud-paso-4-lugar-acceso-component>
  <solicitud-paso-4-instrucciones-component></solicitud-paso-4-instrucciones-component>
  <solicitud-paso-5-ayuda-component></solicitud-paso-5-ayuda-component>
  <solicitud-paso-5-instrucciones-component></solicitud-paso-5-instrucciones-component>
  <solicitud-paso-5-lugar-salida-component></solicitud-paso-5-lugar-salida-component>
  <solicitud-paso-6-placa-component></solicitud-paso-6-placa-component>
  <solicitud-paso-6-razon-social-component></solicitud-paso-6-razon-social-component>
  <solicitud-confirmar-component></solicitud-confirmar-component>
  <solicitud-enviada-component></solicitud-enviada-component>
  <solicitud-contactanos-component></solicitud-contactanos-component>
</template>

<script>
import { mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  email,
  helpers,
  maxLength,
  required,
  requiredIf,
} from "@vuelidate/validators";
import ClienteService from "../../services/ClienteService";
import FormaLlegadaService from "../../services/FormaLlegadaService";
import moment from "moment-timezone";

export default {
  name: "solicitud-component",
  props: {
    slug: String,
    id: String,
  },
  data() {
    return {
      v$: useVuelidate(),
      operation: null,
      eliminar_destino: false,
      cliente_slug: this.slug,
      horarios_formatted: "",
      configuracion: {
        logo_url: "",
      },
      solicitud_info: null,
      solicitud: {
        cliente: null,
        donde_quieres_llegar: "",
        fecha_visita: null,
        forma_llegada: null,
        paso_5: {
          numero_telefonico: "",
          correo_electronico: "",
        },
        paso_6: {
          placa_vehiculo: "",
          es_denominacion: true,
          denominacion: "",
          nombre: "",
        },
      },
      formas_llegada: null,
      punto_acceso_seleccionado: null,
      punto_salida_seleccionado: null,
      fecha_visita_minima: null,
      fecha_visita_maxima: null,
      mapa_puntos_llegada: null,
      mapa_puntos_acceso: null,
      mapa_puntos_salida: null,
      marcadores_puntos_llegada: [],
      marcadores_puntos_acceso: [],
      marcadores_puntos_salida: [],
      contador_puntos_llegada: 2,
      marcador_actual: null,
      marcador_actual_draggable: false,
      mostrar_paso_0: true,
      mostrar_paso_1: false,
      mostrar_paso_2: false,
      mostrar_paso_3: false,
      mostrar_paso_4: false,
      mostrar_paso_5: false,
      mostrar_paso_6: false,
      mostrar_paso_7: false,
    };
  },
  solicitud_service: null,
  cliente_service: null,
  forma_llegada_service: null,
  computed: {},
  methods: {
    ...mapActions("cliente", ["get_by_slug"]),
    ...mapActions("forma_llegada", ["get_all"]),
    ...mapActions("solicitud", ["save", "get_by_id_encriptado"]),
    ...mapActions("configuracion", ["get_"]),
    on_change_propietario() {
      if (!this.solicitud.paso_6.es_denominacion) {
        this.solicitud.paso_6.denominacion = "";
      } else {
        this.solicitud.paso_6.nombre = "";
      }
    },
    fn_ir_paso_2() {
      if (this.marcadores_puntos_llegada.length === 0) {
        this.$toast.error("Debes agregar al menos un destino.", {});
        return;
      }
      this.mostrar_paso_1 = false;
      this.mostrar_paso_2 = true;
    },
    fn_ir_paso_3() {
      if (!this.solicitud.fecha_visita) {
        this.$toast.error("Debes seleccionar la fecha del viaje.", {});
        return;
      }
      this.mostrar_paso_2 = false;
      this.mostrar_paso_3 = true;
    },
    fn_ir_paso_4() {
      if (!this.punto_acceso_seleccionado) {
        this.$toast.error(
          "Debes seleccionar la calle por la cual llegarás a la ciudad.",
          {}
        );
        return;
      }
      this.mostrar_paso_3 = false;
      this.mostrar_paso_4 = true;
    },
    fn_ir_paso_5() {
      if (!this.solicitud.forma_llegada) {
        this.$toast.error(
          "Debes seleccionar el medio de transporte que usarás para llegar.",
          {}
        );
        return;
      }
      this.mostrar_paso_4 = false;
      this.mostrar_paso_5 = true;
    },
    fn_ir_paso_6() {
      if (!this.punto_salida_seleccionado) {
        this.$toast.error(
          "Debes seleccionar la calle por la cual saldrás de la ciudad.",
          {}
        );
        return;
      }

      this.mostrar_paso_5 = false;
      this.mostrar_paso_6 = true;
    },
    fn_ir_paso_7() {
      this.v$.solicitud.paso_5.$touch();

      if (this.v$.solicitud.paso_5.$invalid) return;

      this.mostrar_paso_6 = false;
      this.mostrar_paso_7 = true;
    },
    fn_get_puntos_llegada() {
      const puntos_llegada = [];

      for (const item of this.marcadores_puntos_llegada) {
        puntos_llegada.push({
          latitud: item.getPosition().lat(),
          longitud: item.getPosition().lng(),
        });
      }

      return puntos_llegada;
    },
    fn_confirmar_solicitud() {
      this.v$.solicitud.paso_6.$touch();

      if (this.v$.solicitud.paso_6.$invalid) return;

      const puntos_llegada = this.fn_get_puntos_llegada();

      const model = {
        id: !this.solicitud_info ? 0 : this.solicitud_info.id,
        forma_llegada_id: this.solicitud.forma_llegada,
        servicio: this.solicitud.cliente.servicios[0],
        fecha_visita: this.solicitud.fecha_visita
          ? moment(this.solicitud.fecha_visita)
              .tz("America/Mexico_City")
              .format("YYYY-MM-DD")
          : null,
        numero_telefonico: this.solicitud.paso_5.numero_telefonico,
        correo_electronico: this.solicitud.paso_5.correo_electronico,
        placa_vehiculo: this.solicitud.paso_6.placa_vehiculo,
        es_denominacion: this.solicitud.paso_6.es_denominacion,
        denominacion: this.solicitud.paso_6.denominacion,
        nombre_completo: this.solicitud.paso_6.nombre,
        latitud_puntos_llegada: this.mapa_puntos_llegada.getCenter().lat(),
        longitud_puntos_llegada: this.mapa_puntos_llegada.getCenter().lng(),
        puntos_llegada,
        cliente_punto_acceso_id:
          this.punto_acceso_seleccionado.data.cliente_punto_acceso_id,
        cliente_punto_salida_id:
          this.punto_salida_seleccionado.data.cliente_punto_salida_id,
        cliente_id: this.solicitud.cliente.id,
      };

      this.save({ model }).then(() => {
        this.emitter.emit("solicitud-enviada", this.cliente_slug);
      });
    },
    fn_regresar_a_paso_1() {
      this.mostrar_paso_2 = false;
      this.mostrar_paso_1 = true;
    },
    fn_regresar_a_paso_2() {
      this.mostrar_paso_3 = false;
      this.mostrar_paso_2 = true;
    },
    fn_regresar_a_paso_3() {
      this.mostrar_paso_4 = false;
      this.mostrar_paso_3 = true;
    },
    fn_regresar_a_paso_4() {
      this.mostrar_paso_5 = false;
      this.mostrar_paso_4 = true;
    },
    fn_regresar_a_paso_5() {
      this.mostrar_paso_6 = false;
      this.mostrar_paso_5 = true;
    },
    fn_regresar_a_paso_6() {
      this.mostrar_paso_7 = false;
      this.mostrar_paso_6 = true;
    },
    async fn_get_formas_llegada() {
      await this.get_all().then((response) => {
        if (response) {
          this.formas_llegada = response;
        }
      });
    },
    async fn_get_configuracion() {
      this.get_().then((result) => {
        if (result) {
          this.configuracion = result;

          if (this.configuracion) {
            const link =
              document.querySelector("link[rel*='icon']") ||
              document.createElement("link");
            link.type = "image/png";
            link.rel = "shortcut icon";
            link.href = this.configuracion.logo_url;
            document.getElementsByTagName("head")[0].appendChild(link);
          }
        }
      });
    },
    fn_get_horarios_formatted() {
      const horarios = [];
      const textos = [];

      for (const item of this.solicitud.cliente.horarios) {
        const index = horarios.findIndex(
          (x) => item.de_formatted === x.de && item.a_formatted === x.a
        );

        if (index !== -1) {
          horarios[index]["dias"].push(item.dia_formatted);
        } else {
          horarios.push({
            dias: [item.dia_formatted],
            de: item.de_formatted,
            a: item.a_formatted,
          });
        }
      }

      for (const item of horarios) {
        if (item.dias.length === 1) {
          textos.push(`${item.dias[0]} de ${item.de} a ${item.a}`);
        } else {
          const aux = item.dias.join(", ");
          textos.push(`${aux} de ${item.de} a ${item.a}`);
        }
      }

      this.horarios_formatted = textos.join(", ").toLowerCase();
    },
    async fn_get_by_slug() {
      await this.get_by_slug(this.cliente_slug).then((response) => {
        if (!response) {
          this.$router.push({
            name: "page-not-found-component",
            params: { catchAll: "pagina-no-encontrada" },
          });
        } else {
          if (!response.configuracion) {
            this.$router.push({
              name: "page-not-found-component",
              params: { catchAll: "pagina-no-encontrada" },
            });
          } else {
            this.solicitud.cliente = response;

            if (!this.solicitud.cliente.horario_24_7) {
              this.fn_get_horarios_formatted();
            }

            if (this.solicitud_info) {
              this.fn_cargar_informacion_editar();
            }
          }
        }
      });
    },
    async fn_get_by_id_encriptado() {
      await this.get_by_id_encriptado(this.id).then((response) => {
        if (
          response.solicitud.estatus_solicitud_id !=
          this.$settings.estatus_solicitud.RECHAZADA
        ) {
          this.$router.push({
            name: "page-not-found-component",
            params: { catchAll: "pagina-no-encontrada" },
          });
        } else {
          this.solicitud_info = response.solicitud;
          this.cliente_slug = response.slug;
        }
      });
    },
    fn_cargar_informacion_editar() {
      this.solicitud.fecha_visita = new Date(this.solicitud_info.fecha_visita);

      this.solicitud.forma_llegada = this.solicitud_info.forma_llegada_id;
      this.solicitud.paso_5.numero_telefonico = this.solicitud_info
        .numero_telefonico
        ? this.solicitud_info.numero_telefonico
        : "";
      this.solicitud.paso_5.correo_electronico = this.solicitud_info
        .correo_electronico
        ? this.solicitud_info.correo_electronico
        : "";
      this.solicitud.paso_6.es_denominacion =
        this.solicitud_info.es_denominacion;
      this.solicitud.paso_6.denominacion = this.solicitud_info.denominacion
        ? this.solicitud_info.denominacion
        : "";
      this.solicitud.paso_6.nombre = this.solicitud_info.nombre_completo
        ? this.solicitud_info.nombre_completo
        : "";
      this.solicitud.paso_6.placa_vehiculo = this.solicitud_info.placa_vehiculo
        ? this.solicitud_info.placa_vehiculo
        : "";
    },
    fn_deshabilitar_marcadores_mapa() {
      try {
        for (
          let index = 0;
          index < this.marcadores_puntos_llegada.length;
          index++
        ) {
          const icon = {
            url: `/images/marcadores/${index + 1}.png`,
            scaledSize: new google.maps.Size(30, 40),
          };

          this.marcadores_puntos_llegada[index].setIcon(icon);
        }
      } catch (error) {
        this.$toast.error(
          "Ha ocurrido un error, consulta al administrador.",
          {}
        );
      }
    },
    fn_marcador_eventos(marker) {
      try {
        const $this = this;

        this.fn_insertar_arreglo_marcadores(marker, "puntos_llegada");

        marker.addListener("click", function () {
          $this.fn_deshabilitar_marcadores_mapa();
          $this.marcador_actual = marker;
          const data = $this.marcador_actual.data;
          const icon = {
            url: `/images/marcadores/${data.contador}.png`,
            scaledSize: new google.maps.Size(50, 70),
          };

          $this.marcador_actual.setDraggable(true);
          $this.marcador_actual.setIcon(icon);
          $this.marcador_actual_draggable = true;
        });

        marker.addListener("rightclick", function () {
          if ($this.eliminar_destino) {
            marker.setMap(null);
            $this.marcador_actual = null;
            $this.marcador_actual_draggable = false;
            $this.eliminar_destino = false;
          }
        });
      } catch (error) {
        this.$toast.error(
          "Ha ocurrido un error, consulta al administrador.",
          {}
        );
      }
    },
    fn_eliminar_destino() {
      try {
        if (this.marcador_actual) {
          for (
            let index = 0;
            index < this.marcadores_puntos_llegada.length;
            index++
          ) {
            if (
              this.marcadores_puntos_llegada[index].data.marker_id ==
              this.marcador_actual.data.marker_id
            ) {
              this.eliminar_destino = true;
              new google.maps.event.trigger(
                this.marcadores_puntos_llegada[index],
                "rightclick"
              );
              this.marcadores_puntos_llegada.splice(index, 1);
              break;
            }
          }
        }
      } catch (error) {
        this.$toast.error(
          "Ha ocurrido un error, consulta al administrador.",
          {}
        );
      }
    },
    fn_agregar_destino() {
      try {
        this.fn_deshabilitar_marcadores_mapa();

        if (this.marcadores_puntos_llegada.length + 1 >= 9) {
          this.$toast.error(
            "No puedes seleccionar más de 9 puntos de llegada.",
            {}
          );
        } else {
          const total_puntos_llegada =
            this.marcadores_puntos_llegada.length + 1;

          const lat = this.mapa_puntos_llegada.getCenter().lat();
          const lng = this.mapa_puntos_llegada.getCenter().lng() + 0.003;

          const marker_id = this.fn_get_marker_unique_id(lat, lng);

          const icon = {
            url: `/images/marcadores/${total_puntos_llegada}.png`,
            scaledSize: new google.maps.Size(30, 40),
          };

          const marker = new window.google.maps.Marker({
            position: { lat, lng },
            map: this.mapa_puntos_llegada,
            id: marker_id,
            icon,
            draggable: true,
            data: {
              marker_id,
              contador: total_puntos_llegada,
            },
          });

          this.marcador_actual = marker;
          this.fn_marcador_eventos(marker);
        }
      } catch (error) {
        this.$toast.error(
          "Ha ocurrido un error, consulta al administrador.",
          {}
        );
      }
    },
    fn_insertar_arreglo_marcadores(marker, tipo) {
      if (tipo === "puntos_llegada") {
        this.marcadores_puntos_llegada.push(marker);
      }
    },
    fn_initialize_map() {
      this.$functions.fn_load_maps_script();
      window.init_map = this.fn_init_map;
    },
    fn_init_map() {
      try {
        const $this = this;

        /********************************************** Puntos de llegada **********************************************/
        this.mapa_puntos_llegada = new window.google.maps.Map(
          document.getElementById("mapa_puntos_llegada"),
          {
            center: {
              lat: this.solicitud.cliente.puntos_acceso[0].latitud,
              lng: this.solicitud.cliente.puntos_acceso[0].longitud,
            },
            zoom: 14,
            mapTypeId: "roadmap",
            zoomControl: true,
            draggable: true,
          }
        );

        this.geocoder = new google.maps.Geocoder();

        const input_puntos_llegada = document.getElementById(
          "txt_donde_quieres_llegar"
        );

        const searchBox_puntos_llegada =
          new window.google.maps.places.SearchBox(input_puntos_llegada);

        this.mapa_puntos_llegada.addListener("bounds_changed", () => {
          searchBox_puntos_llegada.setBounds(
            this.mapa_puntos_llegada.getBounds()
          );
        });

        searchBox_puntos_llegada.addListener("places_changed", () => {
          const places = searchBox_puntos_llegada.getPlaces();

          if (places.length == 0) {
            return;
          }

          // For each place, get the icon, name and location.
          const bounds = new window.google.maps.LatLngBounds();

          places.forEach((place) => {
            if (!place.geometry || !place.geometry.location) {
              return;
            }

            if (place.geometry.viewport) {
              // Only geocodes have viewport.
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          this.mapa_puntos_llegada.fitBounds(bounds);
        });

        if (!this.solicitud_info) {
          const lat = this.mapa_puntos_llegada.getCenter().lat();
          const lng = this.mapa_puntos_llegada.getCenter().lng();

          // Primer punto
          const marker_id = this.fn_get_marker_unique_id(lat, lng);

          const icon = {
            url: `/images/marcadores/1.png`,
            scaledSize: new google.maps.Size(30, 40),
          };

          const marker = new window.google.maps.Marker({
            position: { lat, lng },
            map: this.mapa_puntos_llegada,
            id: marker_id,
            draggable: this.marcador_actual_draggable,
            icon,
            data: {
              marker_id,
              contador: 1,
            },
          });

          this.marcador_actual = marker;
          this.fn_marcador_eventos(marker);
        } else {
          if (this.solicitud_info.puntos_llegada.length > 0) {
            for (const item of this.solicitud_info.puntos_llegada) {
              const total_puntos_llegada =
                this.marcadores_puntos_llegada.length + 1;

              const marker_id = this.fn_get_marker_unique_id(
                item.latitud,
                item.longitud
              );

              const icon = {
                url: `/images/marcadores/${total_puntos_llegada}.png`,
                scaledSize: new google.maps.Size(30, 40),
              };

              const marker = new window.google.maps.Marker({
                position: this.fn_get_lat_lng(item.latitud, item.longitud),
                map: this.mapa_puntos_llegada,
                id: marker_id,
                icon,
                draggable: true,
                data: {
                  marker_id,
                  contador: total_puntos_llegada,
                },
              });

              this.fn_marcador_eventos(marker);
            }
          }
        }

        google.maps.event.addListener(
          $this.mapa_puntos_llegada,
          "drag",
          function () {
            if ($this.marcador_actual) {
              if (!$this.marcador_actual_draggable) {
                $this.marcador_actual.setPosition(
                  $this.mapa_puntos_llegada.getCenter()
                );
              }

              $this.marcador_actual.setAnimation(google.maps.Animation.BOUNCE);
            }
          }
        );

        google.maps.event.addListener(
          $this.mapa_puntos_llegada,
          "dragend",
          function () {
            if ($this.marcador_actual) {
              $this.marcador_actual.setAnimation(null);
              $this.marcador_actual.setDraggable(false);
              $this.marcador_actual_draggable = false;

              $this.geocoder.geocode(
                {
                  latLng: $this.marcador_actual.getPosition(),
                },
                function (responses) {
                  if (responses && responses.length > 0) {
                    $this.solicitud.donde_quieres_llegar =
                      responses[0].formatted_address;
                  }
                }
              );
            }
          }
        );

        /********************************************** Puntos de acceso **********************************************/
        this.mapa_puntos_acceso = new window.google.maps.Map(
          document.getElementById("mapa_puntos_acceso"),
          {
            center: {
              lat: this.solicitud.cliente.puntos_acceso[0].latitud,
              lng: this.solicitud.cliente.puntos_acceso[0].longitud,
            },
            zoom: 14,
            mapTypeId: "roadmap",
            zoomControl: true,
            draggable: true,
          }
        );

        if (this.solicitud.cliente.puntos_acceso.length > 0) {
          let contador = 1;
          let $this = this;

          for (const item of this.solicitud.cliente.puntos_acceso) {
            let tamanio = null;
            const lat = item.latitud;
            const lng = item.longitud;
            const marker_id = this.fn_get_marker_unique_id(lat, lng);

            if (this.solicitud_info) {
              tamanio =
                item.id === this.solicitud_info.cliente_punto_acceso_id
                  ? new google.maps.Size(50, 70)
                  : new google.maps.Size(30, 40);
            } else {
              tamanio = new google.maps.Size(30, 40);
            }

            const icon = {
              url: `/images/marcadores/${contador}.png`,
              scaledSize: tamanio,
            };

            const marker = new window.google.maps.Marker({
              position: this.fn_get_lat_lng(lat, lng),
              map: this.mapa_puntos_acceso,
              icon,
              id: marker_id,
              data: {
                contador,
                marker_id,
                cliente_punto_acceso_id: item.id,
              },
            });

            if (
              this.solicitud_info &&
              item.id === this.solicitud_info.cliente_punto_acceso_id
            ) {
              this.punto_acceso_seleccionado = marker;
            }

            this.marcadores_puntos_acceso.push(marker);

            marker.addListener("click", function () {
              for (
                let index = 0;
                index < $this.marcadores_puntos_acceso.length;
                index++
              ) {
                const icon = {
                  url: `/images/marcadores/${index + 1}.png`,
                  scaledSize: new google.maps.Size(30, 40),
                };

                $this.marcadores_puntos_acceso[index].setIcon(icon);
              }

              const data = marker.data;
              const contador = data.contador;
              const icon = {
                url: `/images/marcadores/${contador}.png`,
                scaledSize: new google.maps.Size(50, 70),
              };

              $this.$toast.success(
                `Seleccionaste la opción <img style="width: 20px;display: inline-block;" src="/images/marcadores/${contador}.png"/> para llegar al municipio.`,
                {}
              );

              marker.setIcon(icon);

              $this.punto_acceso_seleccionado = marker;
            });

            contador++;
          }
        }

        /********************************************** Puntos de salida **********************************************/
        this.mapa_puntos_salida = new window.google.maps.Map(
          document.getElementById("mapa_puntos_salida"),
          {
            center: {
              lat: this.solicitud.cliente.puntos_salida[0].latitud,
              lng: this.solicitud.cliente.puntos_salida[0].longitud,
            },
            zoom: 14,
            mapTypeId: "roadmap",
            zoomControl: true,
            draggable: true,
          }
        );

        if (this.solicitud.cliente.puntos_salida.length > 0) {
          let contador = 1;
          let $this = this;

          for (const item of this.solicitud.cliente.puntos_salida) {
            let tamanio = null;
            const lat = item.latitud;
            const lng = item.longitud;
            const marker_id = this.fn_get_marker_unique_id(lat, lng);

            if (this.solicitud_info) {
              tamanio =
                item.id === this.solicitud_info.cliente_punto_salida_id
                  ? new google.maps.Size(50, 70)
                  : new google.maps.Size(30, 40);
            } else {
              tamanio = new google.maps.Size(30, 40);
            }

            const icon = {
              url: `/images/marcadores/${contador}.png`,
              scaledSize: tamanio,
            };

            const marker = new window.google.maps.Marker({
              position: this.fn_get_lat_lng(lat, lng),
              map: this.mapa_puntos_salida,
              icon,
              id: marker_id,
              data: {
                contador,
                marker_id,
                cliente_punto_salida_id: item.id,
              },
            });

            if (
              this.solicitud_info &&
              item.id === this.solicitud_info.cliente_punto_salida_id
            ) {
              this.punto_salida_seleccionado = marker;
            }

            this.marcadores_puntos_salida.push(marker);

            marker.addListener("click", function () {
              for (
                let index = 0;
                index < $this.marcadores_puntos_salida.length;
                index++
              ) {
                const icon = {
                  url: `/images/marcadores/${index + 1}.png`,
                  scaledSize: new google.maps.Size(30, 40),
                };

                $this.marcadores_puntos_salida[index].setIcon(icon);
              }

              const data = marker.data;
              const contador = data.contador;
              const icon = {
                url: `/images/marcadores/${contador}.png`,
                scaledSize: new google.maps.Size(50, 70),
              };

              $this.$toast.success(
                `Seleccionaste la opción <img style="width: 20px;display: inline-block;" src="/images/marcadores/${contador}.png"/> para salir del municipio.`,
                {}
              );

              marker.setIcon(icon);

              $this.punto_salida_seleccionado = marker;
            });

            contador++;
          }
        }
      } catch (error) {
        this.$toast.error(
          "Ha ocurrido un error, consulta al administrador.",
          {}
        );
      }
    },
    fn_get_marker_unique_id(lat, lng) {
      return `${lat}_${lng}`;
    },
    fn_get_lat_lng(lat, lng) {
      return new window.google.maps.LatLng(lat, lng);
    },
    fn_inicializar_fecha_visita() {
      const hoy = new Date();
      const dias_naturales_despues = new Date(
        hoy.getTime() + 14 * 24 * 60 * 60 * 1000
      );
      this.fecha_visita_minima = hoy;
      this.fecha_visita_maxima = dias_naturales_despues;
    },
  },
  validations() {
    return {
      solicitud: {
        paso_5: {
          numero_telefonico: {
            required: helpers.withMessage(
              "El número telefónico es requerido.",
              required
            ),
            maxLengthValue: helpers.withMessage(
              "El número telefónico no puede ser mayor a 10 carácteres.",
              maxLength(13)
            ),
          },
          correo_electronico: {
            required: helpers.withMessage(
              "El correo electrónico es requerido.",
              required
            ),
            email: helpers.withMessage(
              "El correo electrónico no tiene el formato correcto.",
              email
            ),
            maxLengthValue: helpers.withMessage(
              "El correo electrónico no puede ser mayor a 70 carácteres.",
              maxLength(70)
            ),
          },
        },
        paso_6: {
          placa_vehiculo: {
            required: helpers.withMessage(
              "La placa del vehículo es requerida.",
              required
            ),
            maxLengthValue: helpers.withMessage(
              "La placa del vehículo no puede ser mayor a 10 carácteres.",
              maxLength(10)
            ),
          },
          denominacion: {
            required: helpers.withMessage(
              "La denominación o razón social es requerida.",
              requiredIf(this.solicitud.paso_6.es_denominacion)
            ),
            maxLengthValue: helpers.withMessage(
              "La denominación o razón social no puede ser mayor a 200 carácteres.",
              maxLength(200)
            ),
          },
          nombre: {
            required: helpers.withMessage(
              "El nombre completo es requerido.",
              requiredIf(!this.solicitud.paso_6.es_denominacion)
            ),
            maxLengthValue: helpers.withMessage(
              "El nombr completo no puede ser mayor a 70 carácteres.",
              maxLength(70)
            ),
          },
        },
      },
    };
  },
  created() {
    this.cliente_service = new ClienteService();
    this.forma_llegada_service = new FormaLlegadaService();
    this.operation = this.$route.meta.operation;
  },
  async mounted() {
    if (this.operation === "edit") {
      await this.fn_get_by_id_encriptado();
    }

    await this.fn_get_by_slug();
    await this.fn_get_formas_llegada();
    await this.fn_get_configuracion();

    this.fn_initialize_map();
    this.fn_inicializar_fecha_visita();
  },
};
</script>

<style>
span.azul {
  color: #0288d1;
  font-weight: bold;
}

span.rojo {
  color: #d32f2f;
  font-weight: bold;
}

.solicitud {
  width: 100%;
  padding: 30px;
}

.solicitud .logo img {
  width: 100px;
}

.solicitud-paso1,
.solicitud-paso3,
.solicitud-paso4 {
  display: flex;
  flex-wrap: wrap;
}

.solicitud-paso-izquierda {
  width: 45%;
  position: relative;
}

.solicitud-paso-derecha {
  width: 55%;
}

.solicitud-paso-izquierda-contenido {
  margin-top: 20px;
}

.solicitud-paso-izquierda-contenido .formgrid {
  margin-top: 20px;
}

.solicitud-paso-izquierda-contenido-titulo,
.solicitud-paso2-titulo {
  font-size: 30px;
  font-weight: bold;
}

.solicitud-paso-izquierda-contenido-descripcion {
  padding-top: 10px;
}

.solicitud-paso-izquierda-contenido-descripcion span {
  color: #0288d1;
  font-weight: bold;
}

.solicitud-paso2-titulo {
  padding: 10px 0;
  text-align: center;
}

.solicitud-paso2-contenido {
  text-align: center;
  margin-top: 30px;
}

.solicitud-paso5-contenido,
.solicitud-paso6-contenido {
  text-align: center;
  margin-top: 30px;
  width: 100%;
}

.solicitud-paso1-botones,
.solicitud-paso3-botones {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  bottom: 0;
  left: 0;
  justify-content: space-around;
}

.solicitud-paso4-botones {
  width: 45%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
}

.solicitud-paso2-botones,
.solicitud-paso5-botones,
.solicitud-paso6-botones {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}

.solicitud-paso2-botones .boton {
  width: 50%;
  text-align: center;
}

.solicitud-paso5-botones .boton,
.solicitud-paso6-botones .boton {
  width: 50%;
  text-align: center;
}

.solicitud-paso3-botones .boton,
.solicitud-paso4-botones .boton {
  width: 50%;
  text-align: center;
}

.solicitud-paso-derecha-medios-transporte-item-imagen img {
  width: 128px;
  height: 128px;
  object-fit: cover;
}

.solicitud-boton-mas {
  border: none !important;
  font-size: 40px !important;
}

.solicitud-boton-menos {
  border: none !important;
  font-size: 40px !important;
}

.solicitud-paso-derecha-medios-transporte-item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.solicitud-paso-derecha-medios-transporte-item-radio {
  width: 40px;
}

.solicitud-paso-derecha-medios-transporte-item-texto {
  padding: 20px 50px;
  max-width: 300px;
  width: 100%;
}

.solicitud-paso-derecha.paso3 {
  padding-top: 100px;
}

.solicitud-paso5 .italic,
.solicitud-paso6 .italic {
  margin-top: 20px;
}

.solicitud-paso5-contenido-input-contenedor,
.solicitud-paso6-contenido-input-contenedor {
  display: flex;
  width: 100%;
}

.solicitud-paso5-contenido-input,
.solicitud-paso6-contenido-input {
  position: relative;
  display: flex;
  align-items: center;
}

.solicitud-paso5-contenido-input-contenedor input,
.solicitud-paso6-contenido-input-contenedor input {
  width: calc(100% - 30px);
}

.solicitud-paso6-contenido-input-radio {
  margin-right: 10px;
}

.solicitud-paso6-contenido-or {
  padding: 10px 0;
}

.solicitud-paso0-texto {
  padding: 30px 0;
}

.solicitud-paso0-links {
  padding: 30px 0;
  font-weight: bold;
  margin-top: 40px;
  border-top: 1px solid darkgray;
  text-align: center;
}

.solicitud-paso0-links a {
  margin: 0 10px;
}

.solicitud-paso0-logos-imagen {
  width: 50%;
}

.solicitud-paso0-logos-imagen:nth-child(2) {
  display: flex;
  justify-content: end;
}

.solicitud-paso0-logos-imagen img {
  width: 151px;
  object-fit: cover;
}

.solicitud-paso0-logos {
  display: flex;
}

.solicitud-paso0-botones {
  text-align: right;
}

@media screen and (max-width: 1040px) {
  .solicitud-paso-izquierda,
  .solicitud-paso-derecha {
    width: 100%;
  }

  .solicitud-paso1-botones,
  .solicitud-paso3-botones {
    margin-top: 30px;
    position: relative;
  }

  .solicitud-paso-derecha {
    margin-top: 50px;
  }
  .solicitud-paso-derecha.paso3 {
    padding-top: 0;
  }
  .solicitud-paso-derecha {
    margin-top: 10px;
  }
  .solicitud-paso4-botones {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .solicitud-paso-izquierda {
    padding: 0 30px;
    position: initial;
  }

  .solicitud-paso-derecha {
    margin-top: 0;
    position: initial;
  }
  .solicitud-paso1-botones,
  .solicitud-paso3-botones {
    bottom: 20px;
  }
  .boton-continuar .p-button .p-button-icon-left {
    margin-right: 0;
  }
  .boton-continuar {
    font-size: 20px;
  }
}

@media screen and (max-width: 640px) {
  .solicitud-paso0-logos-imagen img {
    width: 128px;
  }
  .solicitud-paso0 {
    padding: 30px;
  }
}

@media screen and (max-width: 480px) {
  .solicitud {
    padding: 20px 0;
  }
  .solicitud-paso-izquierda-contenido-titulo,
  .solicitud-paso2-titulo {
    font-size: 20px;
    font-weight: bold;
  }

  .solicitud-paso1-botones .boton,
  .solicitud-paso3-botones .boton {
    padding: 10px;
  }

  .solicitud-paso-derecha-medios-transporte-item > div {
    width: 100%;
    text-align: center;
  }

  .solicitud-paso-derecha-medios-transporte-item-imagen img {
    margin: 0 auto;
  }
  .solicitud-paso-derecha-medios-transporte-item-texto {
    padding: 20px;
  }
}
</style>
