import moment from 'moment-timezone';

export const functions = {
  fn_load_maps_script: () => {
    const google_key = process.env.VUE_APP_GOOGLE_MAPS_KEY;
    const url = `https://maps.googleapis.com/maps/api/js?key=${google_key}&callback=init_map&libraries=places&v=weekly`;
    const index = window.document.getElementsByTagName("script")[0];
    const script = window.document.createElement("script");
    script.src = url;
    script.async = true;
    script.defer = true;
    index.parentNode.insertBefore(script, index);
  },
  fn_to_currency: (number, minimum = 2, maximum = 2) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: minimum,
      maximumFractionDigits: maximum,
    });

    return formatter.format(number);
  },
  fn_to_format: (number, minimum = 2, maximum = 2) => {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: minimum,
      maximumFractionDigits: maximum,
    });

    return formatter.format(number);
  },
  fn_contador: (desde) => {
    let color = '';
    const desde_formatted = moment(desde).tz("America/Mexico_City").format();
    const diff = moment().diff(desde_formatted);
    const duration = moment.duration(diff);
    const dias = duration.days() * 1440;
    const horas = duration.hours() * 60;
    const minutos = (dias + horas) + duration.minutes();
    const segundos = duration.seconds();

    if (minutos <= 29 && segundos <= 59) {
      color = 'verde';
    } else if ((minutos >= 30 && segundos > 0) && (minutos <= 44 && segundos <= 59)) {
      color = 'naranja';
    } else {
      color = 'rojo';
    }

    const contador = `${minutos}:${segundos}`;

    return {
      contador,
      color
    };
  },
  fn_convert_ruta_to_array: (ruta) => {
    const route = {
      inicio: '',
      way_points: null,
      fin: ''
    };
    const way_points = [];
    let contador = 0;

    for (const item of ruta) {

      if (contador === 0) {
        route.inicio = `${item.ruta_inicio.lat},${item.ruta_inicio.lng}`;
      }

      for (let index = 0; index < item.way_points.length; index++) {
        way_points.push(`${item.way_points[index][0]},${item.way_points[index][1]}`);
      }

      if (ruta.length > 1 && contador < (ruta.length - 1)) {
        way_points.push(`${ruta[contador].ruta_fin.lat},${ruta[contador].ruta_fin.lng}`);
      }

      if (contador === (ruta.length - 1)) {
        route.fin = `${item.ruta_fin.lat},${item.ruta_fin.lng}`;
      }

      contador++;
    }

    route.way_points = way_points.join('|');

    return route;
  },
  fn_descargar_excel: (data, filename) => {
    const temp =
      'data:application/vnd.ms-excel;base64,' + encodeURIComponent(data)
    const download = document.createElement('a')
    download.href = temp
    download.download = filename
    document.body.appendChild(download)
    download.click()
    document.body.removeChild(download)
  }
}