import 'es6-promise/auto';
import {
  createStore
} from 'vuex-extensions'
import {
  Store
} from 'vuex'
import VuexPersistence from 'vuex-persist';
import {
  encryptStorage
} from '../encryption';
import UserStore from './user';
import FormaLlegadaStore from './forma_llegada';
import EstatusSolicitudStore from './estatus_solicitud';
import SolicitudStore from './solicitud';
import ClienteStore from './cliente';
import ConfiguracionStore from './configuracion';
import LandingPageStore from './landing_page';

const vuexLocal = new VuexPersistence({
  storage: encryptStorage
})

const modules = {
  user: UserStore,
  cliente: ClienteStore,
  forma_llegada: FormaLlegadaStore,
  estatus_solicitud: EstatusSolicitudStore,
  configuracion: ConfiguracionStore,
  solicitud: SolicitudStore,
  landing_page: LandingPageStore
};

const state = {
  path: '',
  name: ''
};

const actions = {
  init_cfg_sistema({
    commit
  }) {
    commit('set_cfg_sistema');
  }
};

const mutations = {
  set_cfg_sistema(state) {
    state.path = process.env.VUE_APP_URL;
    state.name = process.env.VUE_APP_NAME;
  }
};

const getters = {
  path: state => state.path,
  system_name: state => state.name
};

const store = createStore(Store, {
  namespaced: true,
  modules,
  state,
  actions,
  mutations,
  getters,
  plugins: [vuexLocal.plugin],
});

export default store;