import LandingPageService from "../services/LandingPageService";

const state = {};

const landing_page_service = new LandingPageService();

const actions = {
  async send_correo({
      commit
    },
    model
  ) {
    return new Promise((resolve, reject) => {

      landing_page_service
        .send_correo(model.formulario)
        .then((result) => {
          resolve(result.data);
        }).catch(error => reject(error));
    });

  },
};

const mutations = {};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};