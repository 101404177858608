import {
  createRouter,
  createWebHistory
} from 'vue-router';

// LandingPage
import LandingPageComponent from './components/LandingPageComponent';

// MasterPage
import MainComponent from './components/MasterPage/MainComponent';

// Unauthorized-component
import PageNotFoundComponent from './components/Layouts/PageNotFoundComponent';

// Solicitudes
import SolicitudComponent from './components/Solicitudes/SolicitudComponent';
import SolicitudMostrarComponent from './components/Solicitudes/SolicitudMostrarComponent';
import SolicitudPagoComponent from './components/Solicitudes/SolicitudPagoComponent';
import SolicitudPagoExitosoComponent from './components/Solicitudes/SolicitudPagoExitosoComponent';
import SolicitudPagoPendienteComponent from './components/Solicitudes/SolicitudPagoPendienteComponent';

const routes = [{
  path: "/:catchAll(.*)",
  name: "page-not-found-component",
  component: PageNotFoundComponent,
}, {
  path: "/",
  name: "landing",
  component: LandingPageComponent,
}, {
  path: '/main',
  component: MainComponent,
  name: 'main',
  children: [{
    path: '/:slug',
    component: SolicitudComponent,
    name: 'solicitud-add',
    meta: {
      operation: 'add'
    },
    props: true
  }, {
    path: '/solicitudes/edit/:id',
    component: SolicitudComponent,
    name: 'solicitud-edit',
    meta: {
      operation: 'edit'
    },
    props: true
  }, {
    path: '/solicitudes/show/:id',
    component: SolicitudMostrarComponent,
    name: 'solicitud-show',
    meta: {
      operation: 'show'
    },
    props: true
  }, {
    path: '/solicitudes/pago/:id',
    component: SolicitudPagoComponent,
    name: 'solicitud-pago',
    props: true
  }, {
    path: '/solicitudes/pago/:id/success',
    component: SolicitudPagoExitosoComponent,
    name: 'solicitud-pago-exito',
    props: true
  }, {
    path: '/solicitudes/pago/:id/pending',
    component: SolicitudPagoPendienteComponent,
    name: 'solicitud-pago-pendiente',
    props: true
  }]
}, ];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;