<template>
    <Dialog
        :modal="true"
        v-model:visible="abrir_modal"
        :closable="true"
        class="col-12 sm:col-12 md:col-6"
    >
        <div class="solicitud-contactanos">
            <div class="solicitud-contactanos-texto">
                Dudas, comentarios o sugerencias, comunícate al correo:<br />
                <strong
                    ><u
                        ><a :href="`mailto:${correo}`">{{ correo }}</a></u
                    ></strong
                >.
            </div>
        </div>
        <template #footer> </template>
    </Dialog>
</template>

<script>
export default {
    name: "solicitud-contactanos-component",
    props: {},
    data() {
        return {
            abrir_modal: false,
            correo: "",
        };
    },
    methods: {},
    validations() {},
    mounted() {
        this.emitter.on("solicitud-contactanos", (correo) => {
            this.correo = correo;
            this.abrir_modal = true;
        });
    },
};
</script>

<style>
.solicitud-contactanos {
    padding: 0 15px;
    display: flex;
}

.solicitud-contactanos-texto {
    padding: 0 20px;
    width: 100%;
}
</style>
