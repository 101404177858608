import axios from './AxiosService';

export default class ClienteService {
  async get_all() {
    return await axios.get('/cliente').then(response => response.data);
  }

  async get_by_slug(slug) {
    return await axios.get(`/cliente/slug/${slug}`).then(response => response.data);
  }

}