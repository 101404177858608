<template>
  <Dialog
    :modal="true"
    v-model:visible="abrir_modal"
    :closable="true"
    class="col-12 sm:col-12 md:col-6"
  >
    <div class="solicitud-paso3-instrucciones">
      <div class="solicitud-paso3-instrucciones-imagen">
        <img src="/images/car.png" />
      </div>
      <div class="solicitud-paso3-instrucciones-texto">
        Selecciona alguna de las calles de salida que ya están precargadas en el
        sistema, solo podrás salir por las calles precargadas.
      </div>
    </div>
    <template #footer>
      <Button
        label="¡Listo!"
        icon="fas fa-check"
        class="p-button-info p-mr-2 p-button-rounded"
        v-tooltip.bottom="'¡Listo!'"
        @click="abrir_modal = false"
      />
    </template>
  </Dialog>
</template>

<script>
export default {
  name: "solicitud-paso-5-instrucciones-component",
  props: {},
  data() {
    return {
      abrir_modal: false,
    };
  },
  methods: {},
  validations() {},
  mounted() {
    this.emitter.on("solicitud-paso-5-instrucciones", () => {
      this.abrir_modal = true;
    });
  },
};
</script>

<style>
.solicitud-paso3-instrucciones {
  padding: 0 15px;
  display: flex;
}

.solicitud-paso3-instrucciones-imagen {
  width: 25%;
}

.solicitud-paso3-instrucciones-imagen img {
  padding: 10px;
}

.solicitud-paso3-instrucciones-texto {
  padding: 0 20px;
  width: 75%;
  font-weight: bold;
  font-size: 25px;
}

.icono-mas {
  font-size: 40px !important;
  color: #689f38;
}

.icono-menos {
  font-size: 40px !important;
  color: #d32f2f;
}

@media screen and (max-width: 520px) {
  .solicitud-paso3-instrucciones {
    flex-wrap: wrap;
  }
  .solicitud-paso3-instrucciones-imagen,
  .solicitud-paso3-instrucciones-texto {
    width: 100%;
  }

  .solicitud-paso3-instrucciones-imagen img {
    margin: 0 auto;
  }
}
</style>
