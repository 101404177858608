export const settings = {
  perfil: {
    SUPERADMINISTRADOR: 1,
    ADMINISTRADOR: 2,
    INVITADO: 3
  },
  estatus_solicitud: {
    PENDIENTE_AUTORIZAR: 1,
    RECHAZADA: 2,
    PENDIENTE_PAGAR: 3,
    PAGADA: 4,
    NO_FUE_PAGADA: 5,
    NO_ATENDIDA: 6,
  }
}