<template>
  <div
    class="solicitud-pago-exitoso"
    v-if="solicitud && configuracion.logo_url"
  >
    <div class="solicitud-pago-exitoso-logo">
      <img :src="configuracion.logo_url" />
    </div>
    <div class="solicitud-pago-exitoso-contenido">
      <div class="item"><b>Solicitud ID:</b> {{ solicitud.id }}</div>
      <div class="item"><b>Servicio:</b> {{ solicitud.servicio.nombre }}</div>
    </div>
    <div class="solicitud-pago-exitoso-texto">
      <p>
        <span
          >IMPORTANTE: estimado conductor, descarga y lleva contigo de manera
          impresa o en formato PDF la autorización de tu solicitud, la cual
          comprueba que has realizado el pago.</span
        ><br /><br />
        También se ha enviado la autorización a tu dirección de correo
        electrónico.
      </p>
    </div>
    <div class="solicitud-pago-exitoso-botones">
      <div class="boton">
        <Button
          label="Nueva solicitud"
          icon="fas fa-plus"
          class="p-button-info mr-2 p-button-rounded"
          v-tooltip.bottom="'Nueva solicitud'"
          @click="fn_nueva_solicitud"
        />
        <Button
          label="Descargar comprobante"
          icon="fas fa-download"
          class="p-button-info mr-2 p-button-rounded"
          v-tooltip.bottom="'Descargar comprobante'"
          @click="fn_descargar_comprobante"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "solicitud-pago-exitoso-component",
  props: {
    id: String,
  },
  data() {
    return {
      configuracion: {
        logo_url: null,
      },
      solicitud: null,
      slug: null,
    };
  },
  computed: {},
  methods: {
    ...mapActions("configuracion", ["get_"]),
    ...mapActions("solicitud", ["get_by_id_encriptado"]),
    fn_descargar_comprobante() {
      window.open(this.solicitud.recibo_pago_pdf_url, "_blank");
    },
    fn_nueva_solicitud() {
      this.$router.push({
        path: `/${this.slug}`,
      });
    },
    async fn_get_configuracion() {
      this.get_().then((result) => {
        if (result) {
          this.configuracion.logo_url = result.logo_url;

          const link =
            document.querySelector("link[rel*='icon']") ||
            document.createElement("link");
          link.type = "image/png";
          link.rel = "shortcut icon";
          link.href = this.configuracion.logo_url;
          document.getElementsByTagName("head")[0].appendChild(link);
        }
      });
    },
    async fn_get_by_id_encriptado() {
      this.get_by_id_encriptado(this.id).then((response) => {
        this.solicitud = response.solicitud;
        this.slug = response.slug;
      });
    },
  },
  validations() {
    return {};
  },
  created() {},
  async mounted() {
    await this.fn_get_by_id_encriptado();
    await this.fn_get_configuracion();
  },
};
</script>

<style>
.solicitud-pago-exitoso {
  padding: 60px;
  width: 50%;
  text-align: center;
  margin: 0 auto;
}

.solicitud-pago-exitoso-contenido .item {
  padding: 5px 0;
}

.solicitud-pago-exitoso-contenido {
  padding: 30px 0 0 0;
}

.solicitud-pago-exitoso-texto p span {
  color: #d32f2f;
  font-weight: bold;
}

.solicitud-pago-exitoso-botones {
  margin-top: 50px;
}

.solicitud-pago-exitoso-texto {
  margin-top: 20px;
}

.solicitud-pago-exitoso-logo img {
  width: 128px;
  height: 128px;
  object-fit: cover;
  margin: 0 auto;
}

@media screen and (max-width: 640px) {
  .solicitud-pago-exitoso {
    width: 100%;
    padding: 30px;
  }
}
</style>
